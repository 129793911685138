/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-27 10:53:52
 * @LastEditTime: 2023-08-02 15:43:45
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/IconFamily/CooperLibrary/index.js
 *
 */
import { intl } from 'di18n-react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import './index.less'

function CooperLibray(props) {
  const navigate = useNavigate()
  const goToLibray = () => {
    window.__OmegaEvent('ep_lab_ck', '', {
      platform: 'new',
    });
    navigate('/library')
  }
  return (
    // <a className='library' href='/library' >{intl.t('实验室')}</a>
    <Tooltip
      overlayClassName='library-shiyanshi-tooltip'
      title={intl.t('实验室')}
      placement={props.type === 'small' ? 'right' : 'top'}
    >
      <i
        className={`dk-iconfont dk-icon-shiyanshi icon-shiyanshi ${props.type === 'small' ? 'icon-shiyanshi-small' : ''}` }
        onClick={goToLibray}
      />
    </Tooltip>
  )
}

export default CooperLibray
