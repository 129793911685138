module.exports = /* html */ `
<div class="space-skeleton-header">
  <div class="space-skeleton-header-title space-skeleton-button">
    <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
      <span class="ant-skeleton-button ant-skeleton-button-round"></span>
    </div>
  </div>
  <div class="space-skeleton-header-search">
    <div class="global-search-wrap">
      <span class="ant-input-group-wrapper">
        <span class="ant-input-wrapper ant-input-group">
          <span class="ant-input-group-addon">
            <div class="search-before">
              <i class="dk-icon-sousuo dk-iconfont" style="color: rgb(187, 187, 187);"></i>
              <p class="search-tag">当前空间
                <span style="cursor: pointer; height: 20px;">
                  <i class="dk-icon-guanbi dk-iconfont" style="font-size: 20px;"></i></span>
              </p>
            </div>
          </span>
          <span class="ant-input-affix-wrapper">
            <input autocomplete="off" placeholder="搜索文档标题、正文、知识库名" class="ant-input" type="text" value="">
            <span class="ant-input-suffix">
              <span class="ant-input-clear-icon ant-input-clear-icon-hidden" role="button" tabindex="-1">
                <span role="img" aria-label="close-circle" class="anticon anticon-close-circle">
                  <svg fill-rule="evenodd" viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z"></path>
                  </svg>
                </span>
              </span>
            </span>
          </span>
          <span class="ant-input-group-addon">
            <span class="search-icon-btn">
              <span class="search-text">高级搜索</span>⌘+J</span></span>
        </span>
      </span>
    </div>
    <div class="cooper-customer-service"><span class="customer-service-bar"></span><div class="newfeature-popup"><span><span class="cooper-customer-service-empty"></span></span></div><div class="cooper-helper-wrap"><div class="cooper-helper"><i class="dk-iconfont dk-icon-gongnengshangxin"></i></div></div><div class="feedback-with-popup"><span><div class="feedback"><div class="content"><i class="dk-iconfont dk-icon-lianxikefu"></i></div></div></span></div></div> 
  </div>
</div>
`
