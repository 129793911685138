import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { createPortal } from 'react-dom';
import { intl, getLocale } from 'di18n-react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './style.module.less';
import classBind from 'classnames/bind';

const cx = classBind.bind(styles);

function GuideModal({ config, finishText, userViewKey }) {
  const { userViewData = {}} = useSelector((state) => state.GlobalData);
  const { setUserViewDataRq } = useDispatch().GlobalData;


  useEffect(() => {
    const userValue = userViewData[userViewKey];
    setVisible(userValue)
  }, [userViewData?.[userViewKey]]);

  const [visible, setVisible] = useState(null);
  const [step, setStep] = useState(0);

  const handleClose = async () => {
    setVisible(false);
    let value = { ...userViewData };
    value[userViewKey] = false;
    if (JSON.stringify(userViewData) === '{}') return;
    setUserViewDataRq(value);
  };

  const handlePrevious = () => {
    setStep((step) => Math.max(0, step - 1));
  };

  const handleNext = () => {
    const result = step + 1;
    if (result >= config.length) {
      handleClose()
    } else {
      setStep(result);
    }
  }

  if (!visible) return null;

  return createPortal(
    <div className={cx('block-editor-guide-dialog')}>
      <div className={cx('block-editor-guid-content')}>
        <span
          className={cx('block-close')}
          onClick={handleClose}>
          <i className={cx('dk-iconfont', 'dk-icon-a-guanbibeifen3', 'close-icon')} />
        </span>
        <div className={cx('guide-container')}>
          <div className={cx('guide-title')}>
            {intl.t(config[step].title)}
          </div>
          <div className={cx('guide-demo')}>
            <img
              className={cx('guide-img')}
              src={config[step].img}
              alt='暂无'/>
          </div>
          <div className={cx('guide-summary')}>
            {intl.t(config[step].summary)}
          </div>
          <div className={cx('guide-footer')}>
            <div className={cx('guide-percent')}>
              {
                config.length > 1 ? `${(step + 1)} / ${(config.length)}` : ''
              }
            </div>
            <div className={cx('guide-operation')}>
              {
                step > 0
                && <Button
                  className={cx('guide-previous')}
                  onClick={handlePrevious}>{intl.t('上一页')}</Button>
              }
              <Button
                type='primary'
                className={cx('guide-next')}
                onClick={handleNext}>{
                step < config.length - 1 ? intl.t('下一页') : finishText
              }</Button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
}

export default GuideModal;
