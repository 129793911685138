import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
// import cls from 'classnames';
import { useState, useCallback } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { getCooperRoute } from '@/routes/route-cooper';
import MoreSystem from '../MoreSystem';
import SpaceContent from '../SpaceContent';
import styles from './style.module.less';
import stylesCommon from '../style.module.less';
import { Popover, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { openNewWindow } from '@/utils';
import Tips from '@/components/Tips';
import IconFamily from '@/components/LayoutCooper/IconFamily';
import { entryEnhance } from '@/utils/entryEnhance';
import Logo from '../Logo';
import { isDC } from '@/utils/index';
import navClick from '../utils';

const cxB = classBind.bind(styles);
const cxC = classBind.bind(stylesCommon);

function AsideSmall({ overlayClassName }) {
  const [visible, setVisible] = useState();
  const { pathname } = useLocation();
  const { teamId, knowledgeId } = useParams();
  const { knowledgeDetail, hasTeamParam, knowledgeSpaceType } = useSelector(
    (state) => state.KnowledgeData,
  );
  const { asideTeamList, toastPersonTrashTip, userViewData } = useSelector((state) => state.GlobalData);
  const { setToastPersonTrashTip, setUserViewDataRq } = useDispatch().GlobalData;

  const onVisibleChange = (v) => {
    setVisible(v);
  };

  const spaceId = teamId
    ?? (knowledgeDetail.metisSpaceId == knowledgeId
      ? knowledgeDetail.belongSpaceId
      : null);

  // const judgeSpace = () => {
  //   if (spaceId) {
  //     const leftItem = asideTeamList.find((team) => team.id == spaceId);
  //     if (!leftItem) {
  //       return intl.t('团队空间');
  //     }

  //     return leftItem.name;
  //   }
  //   return null;
  // };

  const judgeSpaceKey = () => {
    if (spaceId) {
      const leftItem = asideTeamList.find((team) => team.id == spaceId);
      if (!leftItem) {
        return 'teamSpace';
      }

      return leftItem.id;
    }
  };

  // const activeName = useCallback(
  //   (item) => {
  //     // 知识库根据权限决定选中
  //     // 团队空间根据是否有在侧边栏决定
  //     const { path, name } = item;
  //     if (pathname.includes('/tome') || pathname.includes('/fromme')) return intl.t('分享');
  //     if (path === pathname) return name();
  //     if (pathname.includes('/knowledge/')) {
  //       if (knowledgeSpaceType === 'PERSONAL_SPACE') return intl.t('个人空间');
  //       if (knowledgeSpaceType === 'NONE') return intl.t('知识库');
  //       if (knowledgeSpaceType === 'TEAM_SPACE' && hasTeamParam === false) { return intl.t('知识库'); }
  //       if (knowledgeSpaceType === 'TEAM_SPACE' && hasTeamParam) { return judgeSpace(name()); }
  //     }
  //     if (pathname.includes('/team-file')) return judgeSpace(name());
  //     if (pathname.includes('/files/')) return intl.t('个人空间');
  //     if (pathname === '/knowledge') return intl.t('知识库');
  //     return '';
  //   },
  //   [pathname, asideTeamList, knowledgeSpaceType, hasTeamParam, Number(spaceId)],
  // );

  const activeKey = useCallback(
    (item) => {
      const { path, key } = item;
      if (pathname.includes('/tome') || pathname.includes('/fromme')) return 'share';
      if (path === pathname) return key;
      if (pathname.includes('/team-file')) return judgeSpaceKey();
      if (pathname.includes('/files/')) return 'personSpace';
      if (pathname === '/knowledge') return 'knowledge';
      return '';
    },
    [pathname, asideTeamList, Number(spaceId)],
  );

  const isSpaceActive = pathname.includes('/team-file')
    || pathname.includes('/team-folder')
    || (pathname.includes('/knowledge')
      && knowledgeSpaceType === 'TEAM_SPACE'
      && hasTeamParam);

  return (
    <div className={`${cxC('aside', 'aside-small-wrap')} ${cxB(overlayClassName)}`} >
      {!isDC() && <Logo isLarge={false} />}
      <div className={cxB('aside-small-box', 'os-scrollbar', 'aside-small-box-media')}>
        <ul className={cxB('aside-small')}>
          {getCooperRoute().asideRoute.map((item) => {
            if (item.name() === intl.t('团队空间')) {
              return (
                <Popover
                  placement="rightTop"
                  visible={visible}
                  onVisibleChange={onVisibleChange}
                  overlayClassName={cxB('space-content-popover')}
                  content={
                    <>
                      {/* TODO:点击or hover样式 */}
                      <NavLink
                        className={cxB('title-space')}
                        key={'/team-folder'}
                        to={'/team-folder'}
                      >
                        <div className={cxB('title-text')}>
                          {intl.t('团队空间')}
                        </div>
                        <i
                          className={cxC(
                            'dk-iconfont',
                            'dk-icon-zuojiantoujuzhong4px',
                            'icon-arrow-right',
                          )}
                        />
                      </NavLink>
                      <SpaceContent
                        OverlayClassName={cxB('popover-space')}
                        onChoose={() => {
                          onVisibleChange(false);
                        }}
                        activeKey={activeKey(item)}
                      />
                    </>
                  }
                  key={item.path}
                >
                  <li
                    className={cxC('item-name')}
                    key={item.path}
                    onClick={() => navClick(item)}>
                    <NavLink
                      end
                      key={item.path}
                      to={item.path}
                      className={`${cxB('item-name-normal')} ${cxC(
                        'item-name-normal',
                        {
                          'item-name-active': isSpaceActive,
                        },
                      )}`}
                    >
                      {({ isActive }) => {
                        return (
                          <div className={cxC('item-small-container')}>
                            <i
                              className={cxC(
                                'dk-iconfont',
                                'aside-icon',
                                'icon-aside-large',
                                isActive || isSpaceActive
                                  ? item.iconSelected
                                  : item.icon,
                              )}
                            />
                            <span
                              className={cxB({
                                'small-name': true,
                              })}><span>
                                {intl.t('团队')}
                              </span></span>
                          </div>
                        );
                      }}
                    </NavLink>
                  </li>
                </Popover>
              );
            }
            if (item.path === '/dk') {
              return (
                <Tooltip
                  title={item.name()}
                  key={item.path}
                  placement="right"
                  showArrow={false}
                  overlayStyle={{ paddingLeft: '4px' }}
                >
                  <li
                    className={`${cxC('item-name')} ${cxB('item-name')}`}
                    key={item.path}
                    onClick={() => navClick(item)}
                  >
                    <div
                      key={item.path}
                      className={`${cxB('item-name-normal')} ${cxC(
                        'item-name-normal',
                      )}`}
                      onClick={() => {
                        let local = window.location.origin;
                        openNewWindow(`${local}/knowledge`)
                      }}
                    >
                      <div className={cxC('item-small-container')}>
                        <i
                          className={cxC(
                            'dk-iconfont',
                            'aside-icon',
                            'icon-aside-large',
                            item.icon,
                          )}
                        />
                        <span
                          className={cxB({
                            'small-name': true,
                          })}>
                          <span>
                            {intl.t('知识库1')}
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>
                </Tooltip>
              );
            }
            if (item.path === '/trash') {
              return (
                <Tooltip
                  title={item.name()}
                  key={item.path}
                  placement="right"
                  showArrow={false}
                  overlayStyle={{ paddingLeft: '4px' }}
                >
                  <li
                    className={`${cxC('item-name')} ${cxB('item-name')}`}
                    key={item.path}
                    onClick={() => navClick(item)}
                  >
                    <NavLink
                      end
                      key={item.path}
                      to={item.path}
                      className={`${cxB('item-name-normal')} ${cxC(
                        'item-name-normal',
                        {
                          'item-name-active': item.key === activeKey(item),
                        },
                      )}`}
                    >
                      {({ isActive }) => {
                        return (
                          <Tooltip
                            title={() => <Tips
                              overlayClassName='blue-tip'
                              text={'被删除的内容已进入"回收站"，30天内你可以在这里找回。'}
                              isShow={true}
                              onClose={() => {
                                setToastPersonTrashTip(false);
                                let data = { ...userViewData };
                                data.New_Trash_Person_Close = '-1';
                                if (JSON.stringify(userViewData) === '{}') return;
                                setUserViewDataRq(data);
                              }}
                            />}
                            color='#047FFE'
                            placement="right"
                            visible={toastPersonTrashTip}
                            arrowPointAtCenter={true}
                            overlayClassName={'new-functions-tooltip'}
                          >
                            <div className={cxC('item-small-container')}>
                              <i
                                className={cxC(
                                  'dk-iconfont',
                                  'aside-icon',
                                  'icon-aside-large',
                                  isActive || item.key === activeKey(item)
                                    ? item.iconSelected
                                    : item.icon,
                                )}
                              />
                              <span
                                className={cxB({
                                  'small-name': true,
                                })}>
                                <span>
                                  {item.name() === intl.t('个人空间') ? intl.t('个人') : (item.name() === intl.t('收藏') ? intl.t('收藏1') : item.name())}
                                </span>
                              </span>
                            </div>
                          </Tooltip>
                        );
                      }}
                    </NavLink>
                  </li>
                </Tooltip>
              )
            }
            return (
              <Tooltip
                title={item.name()}
                key={item.path}
                placement="right"
                showArrow={false}
                overlayStyle={{ paddingLeft: '4px' }}
              >
                <li
                  className={`${cxC('item-name')} ${cxB('item-name')}`}
                  key={item.path}
                  onClick={() => navClick(item)}
                >
                  <NavLink
                    end
                    key={item.path}
                    to={item.path}
                    className={`${cxB('item-name-normal')} ${cxC(
                      'item-name-normal',
                      {
                        'item-name-active': item.key === activeKey(item),
                      },
                    )}`}
                  >
                    {({ isActive }) => {
                      return (
                        <div className={cxC('item-small-container')}>
                          <i
                            className={cxC(
                              'dk-iconfont',
                              'aside-icon',
                              'icon-aside-large',
                              isActive || item.key === activeKey(item)
                                ? item.iconSelected
                                : item.icon,
                            )}
                          />
                          <span
                            className={cxB({
                              'small-name': true,
                            })}>
                            <span>
                              {item.name() === intl.t('个人空间') ? intl.t('个人') : (item.name() === intl.t('收藏') ? intl.t('收藏1') : item.name())}
                            </span>
                          </span>
                        </div>
                      );
                    }}
                  </NavLink>
                </li>
              </Tooltip>
            );
          })}
          {entryEnhance(<MoreSystem>
            {
              <li
                className={cxC('item-name')}
                key={'more'}>
                <div
                  className={`${cxC('item-name-normal')} ${cxB(
                    'item-name-normal',
                  )}`}
                >
                  <div className={cxC('item-small-container')}>
                    <i
                      className={cxC(
                        'dk-iconfont',
                        'aside-icon',
                        'icon-aside-large',
                        'dk-icon-gengduoxitong',
                      )}
                    />
                    <span
                      className={cxB({
                        'small-name': true,
                      })}>
                      <span>
                        {intl.t('更多1')}
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            }
          </MoreSystem>)}
        </ul>
      </div>
      <div
        className={cxC({
          'left-action': true,
          'left-action-small': true,
        })}
      >
        <IconFamily isLarge={false} />
      </div>
    </div>

  );
}

export default AsideSmall;
