import { intl } from 'di18n-react';
/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-18 11:49:04
 * @LastEditTime: 2023-08-02 17:52:15
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/Aside/SpaceContent/index.js
 *
 */
import { doPinned, doUnPinned } from '@/service/cooper/index';
import { Tooltip, message } from 'antd';
import cls from 'classnames';
import classBind from 'classnames/bind';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './style.module.less';
import { initTeamSpaceList } from '@/components/CooperTeamList';

const cx = classBind.bind(styles);

function SpaceContent({ OverlayClassName = '', onChoose, activeKey }) {
  const dispatch = useDispatch();
  const { getAsideTeamList } = dispatch.GlobalData;
  const { asideTeamList } = useSelector((state) => state.GlobalData);
  const { setActiveListTab } = dispatch.TeamData;
  // const { getTeamListInitRecent, getTeamListInitMine, getTeamListInitNotMine } = dispatch.CooperTeamList;
  const { pathname } = useLocation();
  const { activeOwnerType } = useSelector((s) => s.CooperTeamList);


  useEffect(() => {
    getAsideTeamList();
  }, []);

  const onPinned = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    if (item.pinned) {
      doUnPinned(item.id);

      setTimeout(() => {
        getAsideTeamList();
        message.success(intl.t('已取消置顶'));
      }, 800);
    } else {
      doPinned(item.id);

      setTimeout(() => {
        getAsideTeamList();
        message.success(intl.t('置顶成功'));
      }, 800);
    }

    setTimeout(() => {
      if (pathname.includes('/team-folder')) {
        // getTeamListInitRecent();
        // getTeamListInitMine({});
        // getTeamListInitNotMine();
        initTeamSpaceList(activeOwnerType);
      }
    }, 800);
  };

  return (
    <ul className={cx('space-content-wrap', OverlayClassName, 'os-scrollbar')}>
      {asideTeamList?.map((item) => {
        return (
          <li
            className={cx('space-item')}
            key={item.id}
            onClick={() => {
              window.__OmegaEvent('ep_leftbar_teamspace_ck', '', {
                platform: 'new',
              });
              return onChoose && onChoose(item);
            }}
          >
            {/* TODO:浮层选中态 */}
            <div
              className={cx('wrap')}
              onClick={() => {
                setActiveListTab('0');
              }}>
              <NavLink
                key={item.id}
                to={`/team-file/${item.id}/home`}
                className={`${cls('space-item-nav')} ${cx('space-item-normal', {
                  'space-item-active': item.id === activeKey,
                })}`}
              >
                <div className={cx('content-wrap')}>
                  <div className={cx('left-wrap')}>
                    <i
                      className={cx('dk-iconfont', 'dk-icon-dian', 'icon-dian')}
                    />
                    <div className={cx('item-text')}>{item.name}</div>
                  </div>
                  <Tooltip title={`${item.pinned ? intl.t('取消置顶') : intl.t('置顶')}`}>
                    <i
                      className={cx(
                        'dk-iconfont',
                        'icon-pin',
                        `${item.pinned
                          ? 'dk-icon-quxiaozhiding'
                          : 'dk-icon-zhiding4px'
                        }`,
                      )}
                      onClick={(e) => onPinned(e, item)}
                    />
                  </Tooltip>
                </div>
              </NavLink>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default SpaceContent;
