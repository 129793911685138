import { fromJS } from 'immutable';
import engine from './engine';
import TaskPool from './engine/tower';
import { uploadSize, uploadStatus, uploadContainerStatus } from './util/constants';
import SyncLock from './util/syncLock';

const dFunc = () => {};
const dPFunc = Promise.resolve(false);

export { uploadStatus, uploadContainerStatus, SyncLock };

export default class Upload {
  constructor(props) {
    this.hooks = {
      handelError: props.handelError || dFunc,
      initIdsPromise: props.initIdsPromise || dPFunc,
      refreshResource: props.refreshResource || dFunc,
      checkFileSecondsFn: props.checkFileSecondsFn || dPFunc,
      sliceSeconds: props.sliceSeconds || dPFunc,
      sliceUpload: props.sliceUpload || dPFunc,
      fileComplete: props.fileComplete || dPFunc,
    };
    this.config = {
      sliceSize: props.sliceSize || uploadSize.sliceSize,
      sliceBoundary: props.sliceBoundary || uploadSize.sliceBoundary,
      numberOfThreads: props.numberOfThreads || uploadSize.numberOfThreads,
      isCheckFileSeconds: props.isCheckFileSeconds || false,
      isSliceSeconds: props.isSliceSeconds || false,
      sumMd5s: {},

    };
    this.goOn = this.goOn.bind(this);
    this.pause = this.pause.bind(this);
    this.cancel = this.cancel.bind(this);
    this.retry = this.retry.bind(this);
    this.cancelAll = this.cancelAll.bind(this);
    this.reset = this.reset.bind(this);
    this.uFiles = this.uFiles.bind(this);
    this.uFolders = this.uFolders.bind(this);
    this.triggerUFiles = this.triggerUFiles.bind(this);
    this.triggerUFolders = this.triggerUFolders.bind(this);
  }

  goOn(fileItem) { TaskPool.getInstance().goOn(fromJS(fileItem)); }

  pause(fileItem) { TaskPool.getInstance().pause(fromJS(fileItem)); }

  cancel(fileItem) { TaskPool.getInstance().cancel(fromJS(fileItem)); }

  retry(fileItem) { TaskPool.getInstance().retry(fromJS(fileItem)); }

  cancelAll(fileItem) { TaskPool.getInstance().cancelAll(fromJS(fileItem)); }

  reset(fileItem) { TaskPool.getInstance().reset(fromJS(fileItem)); }

  uploadAnything(files, IsExternalTeam) {
    engine.uploadAnything(files,
      { hooks: this.hooks, config: this.config, IsExternalTeam });
  }

  uFiles(files) { engine.checkFiles(files, { isDir: false, hooks: this.hooks, config: this.config }); } // 这个好像目前没用到

  uFolders(files) { engine.uFolders(files, { isDir: true, hooks: this.hooks, config: this.config }); }

  triggerUFiles(IsExternalTeam) {
    engine.openFileChooserDialog(false, IsExternalTeam)(this.hooks, this.config);
  }

  triggerUFolders(IsExternalTeam) { engine.openFileChooserDialog(true, IsExternalTeam)(this.hooks, this.config); }
}
