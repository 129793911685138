/* eslint-disable camelcase */
import TextOnlyModal from '@/components/TextOnlyModal';
import Upload from '@/components/Upload/UploadEngine';
import UploadTo from '@/components/Upload/UploadTo';
import { TEAM_REG_LONG } from '@/constants/reg';
import withRouter from '@/hooks/withRouter';
import { acceptFileSuffixes } from '@/constants/uploadWiki';
import {
  checkTeamFoldPermission,
  duplicateName,
  fileComplete,
  sliceSeconds,
  sliceUpload,
} from '@/service/cooper/upload';
import { supportWebkitdirectory } from '@/utils/cooperutils';
import miniBus from '@/utils/miniBus';
import { Checkbox, Modal, message } from 'antd';

import { intl } from 'di18n-react';
import { flatten } from 'ramda';
import { connect } from 'react-redux';
import Body from './body';
import { uploadError, uploadSize } from './constants';
import { createTaskAndGetIds } from './create.service';
import Header from './header';
import './index.less';
import { getFilePath, getPathByIds } from './util/index';
import { checkFilesByType, getSizeOrAmountStatus } from './validate';

function readEntriesAsync(reader) {
  return new Promise((resolve, reject) => {
    reader.readEntries(
      (entries) => {
        resolve(entries);
      },
      (error) => reject(error),
    );
  });
}

async function readFiles(reader) {
  let result = [];
  let read = async function () {
    let entries = await readEntriesAsync(reader);
    if (entries.length > 0) {
      result = result.concat(entries);
      await read();
    }
  };
  await read();
  return result;
}

class CooperUploadList extends React.Component {
  constructor() {
    super(...arguments);
    this.counter = 0;
    this.remember = false;
    this.rememberOperation = null;
    this.uploadToBack = null;
    this.spaceInfo = null;
    this.position = {
      teamId: 0,
      parentId: 0,
    };
    this.syncLock = false;
  }

  state = {
    listSwitch: true,
    showListSwitch: true,
    showUploadTo: false,
    filesUploadTo: [],
    showDrag: false,
    numberOfThreads: 5,
  };

  componentDidMount() {
    document.body.addEventListener('dragenter', this.onDragEnter);
    document.body.addEventListener('dragover', this.onDragOver);
    document.body.addEventListener('drop', this.onDrop);
    document.body.addEventListener('dragleave', this.onDragLeave);
    this.up = new Upload({
      handelError: this.handelError,
      initIdsPromise: this.initIdsPromise,
      refreshResource: this.refreshResource,
      isCheckFileSeconds: true,
      checkFileSecondsFn: this.checkFileSecondsFn,
      isSliceSeconds: true,
      sliceSeconds: this.sliceSeconds,
      sliceUpload: this.sliceUpload,
      fileComplete: this.fileComplete,
      sliceSize: uploadSize.sliceSize,
      sliceBoundary: uploadSize.sliceBoundary,
      numberOfThreads: uploadSize.numberOfThreads,
    });
    this.props.setUploadCase(this.up);
  }

  componentWillUnmount() {
    document.body.removeEventListener('dragenter', this.onDragEnter);
    document.body.removeEventListener('dragover', this.onDragOver);
    document.body.removeEventListener('drop', this.onDrop);
    document.body.removeEventListener('dragleave', this.onDragLeave);

    if (window.onbeforeunload) {
      window.onbeforeunload = null;
    }
  }

  setSyncLock = (status) => {
    this.syncLock = status;

    if (this.syncLock && !window.onbeforeunload) {
      window.onbeforeunload = function () {
        return intl.t('当前页面存在未完成的上传任务，您确认要离开此页面么?');
      };
    }

    if (!this.syncLock && window.onbeforeunload) {
      window.onbeforeunload = null;
    }
  };

  onDragEnter = (e) => {
    // 处理普通拖拽与拖拽上传
    if (++this.counter >= 1 && e.dataTransfer.types.includes('Files')) {
      this.setState({
        showDrag: true,
      });
    }
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragLeave = () => {
    if (--this.counter <= 0) {
      this.setState({
        showDrag: false,
      });
    }
  };

  scanFiles = (item, config) => {
    if (item.isFile) {
      return new Promise((resolve) => {
        item.file((file) => {
          if (file.name !== '.DS_Store') {
            file.isDir = config.isDir;
            file.fullPath = item.fullPath;
            config.filesContainer.push(file);
          }

          resolve(0);
        });
      });
    }

    if (item.isDirectory) {
      return new Promise((resolve) => {
        const reader = item.createReader();
        const ps = [];
        readFiles(reader).then((entries) => {
          entries.forEach((entry) => {
            ps.push(
              this.scanFiles(entry, {
                filesContainer: config.filesContainer,
                isDir: true,
              }),
            );
          });

          return Promise.all(ps).then(() => resolve(0));
        });
      });
    }

    return Promise.resolve(1);
  };

  onDrop = (e) => {
    const { cooperate_type } = this.props;
    const IsExternalTeam = cooperate_type ? cooperate_type === 'IN_OUT' : null;

    e.preventDefault();
    // 判断是否包含文件
    if (!e.dataTransfer.types.includes('Files')) {
      return;
    }

    window.__OmegaEvent('ep_upload_drag_choose_ck', '', {
      platform: 'new',
    });
    window.__OmegaEvent('ep_upload_drag_ck');
    this.counter = 0;
    this.setState({
      showDrag: false,
    });
    if (!supportWebkitdirectory()) {
      const { files } = e.dataTransfer;
      // IsExternalTeam
      this.props.uploadCase.uploadAnything(files, IsExternalTeam);
      return;
    }

    const { items } = e.dataTransfer;
    const ps = [];
    const filesContainer = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      ps.push(
        this.scanFiles(item, {
          filesContainer,
          isDir: false,
        }),
      );
    }


    Promise.all(ps).then(() => {
      if (filesContainer.length === 0) {
        throw new Error('cancel upload');
      }
      // isDir IsExternalTeam
      this.props.uploadCase.uploadAnything(filesContainer, IsExternalTeam);
    }).catch((error) => {
      if (error.message === 'cancel upload') {
        Modal.error({
          title: intl.t('上传失败'),
          content: intl.t('文件夹为空，请重新选择文件。'),
          okText: intl.t('知道了'),
        });
      }
    });
  };

  // MSG: 请求check-file
  checkFileSecondsFn = (ticket) => {
    return Promise.resolve({ exist: false });
  };

  sliceSeconds = (ticket) => {
    return sliceSeconds(ticket);
  };

  sliceUpload = (buffer) => {
    return sliceUpload(buffer);
  };

  fileComplete = (ticket) => {
    return fileComplete(ticket).then((res) => {
      // 文件上传完成调用接口认为文件上传成功---用来计算文件上传成功数
      window.__OmegaEvent('ep_upload_success_ck');
      if (window.uploadCount) {
        window.uploadCount.success++;
      }
      console.log(window.uploadCount.success, intl.t('成功+1'));
      miniBus.dispatchEvent('folderCreated');
      return res;
    });
  };

  refreshResource = (taskPool) => {
    this.props.setTaskPool(taskPool);
  };


  hintDuplicateName = async (name) => {
    const strategy = await new Promise((reslove) => {
      if (this.remember) {
        reslove(this.rememberOperation);
        return;
      }

      TextOnlyModal.confirm({
        title: intl.t(
          '该位置已经存在名称为{name}的文件，您要用正在上传的文件替换它吗？',
          { name },
        ),
        type: 'ask',
        content: (
          <Checkbox
            className="cooper-upload-hint-box"
            onChange={(e) => {
              this.remember = e.target.checked;
            }}
          >
            {intl.t('后续操作保持一致')}
          </Checkbox>
        ),

        okText: intl.t(' 替换 '),
        cancelText: intl.t('保留两者'),
        onDestroy: () => {
          reslove(0);
        },
        onOk: () => {
          this.rememberOperation = 1;
          reslove(1);
          window.__OmegaEvent('ep_uploadlist_replace_ck', '', {
            platform: 'new',
          });
        },
        onCancel: () => {
          this.rememberOperation = 2;
          reslove(2);
          window.__OmegaEvent('ep_uploadlist_keepboth_ck', '', {
            platform: 'new',
          });
        },
      });
    });
    return strategy;
  };

  duplicateName = (files, teamId, parentId) => {
    const paths = [];

    for (const file of files) {
      let filePath = getFilePath(file);

      if (filePath.indexOf('/') === 0) {
        filePath = filePath.substring(1);
      }

      paths.push(filePath);
    }

    return duplicateName(paths, teamId, parentId, false).then(
      async (data = []) => {
        const strategyFiles = [];

        for (const file of files) {
          strategyFiles.push({
            file,
            strategy: 0,
          });
        }

        for (const { resourceName, exist } of data) {
          if (exist) {
            await this.hintDuplicateName(resourceName).then((resStrategy) => {
              if (resStrategy === 0) {
                throw new Error('cancel upload');
              }

              for (const strategyFile of strategyFiles) {
                let dirName = getFilePath(strategyFile.file);

                if (dirName.indexOf('/') === 0) {
                  dirName = dirName.substring(1);
                }

                if (dirName === resourceName) {
                  strategyFile.strategy = resStrategy;
                }
              }
            });
          }
        }

        return strategyFiles;
      },
    );
  };

  duplicateNameDir = (files, teamId, parentId) => {
    const dirSet = new Set();

    for (const file of files) {
      let filePath = getFilePath(file);

      if (filePath.indexOf('/') === 0) {
        filePath = filePath.substring(1);
      }

      dirSet.add(filePath.substring(0, filePath.indexOf('/')));
    }

    return duplicateName([...dirSet], teamId, parentId, true).then(
      async (data = []) => {
        const strategyFiles = [];

        for (const file of files) {
          strategyFiles.push({
            file,
            strategy: 0,
          });
        }

        for (const { resourceName, exist } of data) {
          if (exist) {
            await this.hintDuplicateName(resourceName).then((resStrategy) => {
              if (resStrategy === 0) {
                throw new Error('cancel upload');
              }

              for (const strategyFile of strategyFiles) {
                let filePath = getFilePath(strategyFile.file);

                if (filePath.indexOf('/') === 0) {
                  filePath = filePath.substring(1);
                }

                const dirName = filePath.substring(0, filePath.indexOf('/'));

                if (dirName === resourceName) {
                  strategyFile.strategy = resStrategy;
                }
              }
            });
          }
        }

        return strategyFiles;
      },
    );
  };

  checkNameAndGetStragy = async (files, isDir, teamId, parentId) => {
    const strategyFiles = [];
    const dirFiles = [];
    const normalFiles = [];

    for (const file of files) {
      if (typeof file.isDir === 'boolean') {
        if (file.isDir) {
          dirFiles.push(file);
        } else {
          normalFiles.push(file);
        }
      } else if (isDir) {
        dirFiles.push(file);
      } else {
        normalFiles.push(file);
      }
    }

    if (dirFiles.length > 0) {
      strategyFiles.push(
        ...(await this.duplicateNameDir(dirFiles, teamId, parentId)),
      );
    }

    if (normalFiles.length > 0) {
      strategyFiles.push(
        ...(await this.duplicateName(normalFiles, teamId, parentId)),
      );
    }

    return strategyFiles;
  };

  mergeValidationFiledFiles = (files, validationFiledFiles) => {
    const strategyfiles = [];

    for (const file of files) {
      const formatFile = {
        file,
        strategy: 0,
      };

      for (const validationFiledFile of validationFiledFiles) {
        if (getFilePath(file) === validationFiledFile.filePath) {
          formatFile.strategy = validationFiledFile.strategy;
        }
      }

      strategyfiles.push(formatFile);
    }

    return strategyfiles;
  };

  createTaskAndGetIds = (formatStrategyFiles, teamId, parentId) => {
    window.__OmegaEvent('ep_webspace_filesupload_bt');
    const startTime = +new Date();
    return createTaskAndGetIds({
      teamId,
      parentId,
    })(formatStrategyFiles)
      .then(({ taskId, items, token }) => {
        let strategyFiles = [];
        try {
          strategyFiles = formatStrategyFiles.map((item) => {
            item.fileId = items.filter((f) => {
              return (
                f.path
                === (getFilePath(item.file).indexOf('/') === 0
                  ? getFilePath(item.file)
                  : `/${getFilePath(item.file)}`)
              );
            })[0].fileId;
            return item;
          });
        } catch (error) {
          console.log(error, 'error');
        }
        window.__uploadCollect = window.__uploadCollect || [];
        window.__uploadCollect = window.__uploadCollect.concat(strategyFiles.map((fileItem) => {
          return {
            id: fileItem.fileId,
            name: fileItem.file.name,
            size: fileItem.file.size,
            type: fileItem.file.type,
            startTime,
          };
        }));
        return {
          taskId,
          token,
          strategyFiles,
          other: {
            teamId,
            parentId,
          },
        };
      })
      .catch(() => {
        if (Object.keys(this.props.taskPool.tasks).length === 0) {
          message.error(intl.t('上传失败'));
          this.setSyncLock(false);
          this.props.switchUploadList(false);
          this.setState({
            showListSwitch: true,
          });
          this.setState({
            listSwitch: true,
          });
          this.props.triggerUploaded(this.position);
        }

        throw new Error('cancel upload');
      });
  };

  getPosition = () => {
    if (/^\/disk$|^\/files\/\d+$/.test(window.location.pathname)) {
      return 'personal';
    }

    // if (/^\/team-file\/\d+\/?\d*$/.test(window.location.pathname)) {
    if (TEAM_REG_LONG.test(window.location.pathname)) {
      return 'team';
    }

    return 'other';
  };

  getMatchPathInfo = () => {
    let personal = window.location.pathname.match(/^\/disk$|^\/files\/(\d+)$/);
    // let team = window.location.pathname.match(/^\/team-file\/(\d+)\/?(\d*)$/);
    let team = window.location.pathname.match(TEAM_REG_LONG)

    return personal || team;
  };

  checkUploadPremossion = (spaceInfo) => {
    const { teamId, parentId } = spaceInfo;
    if (!teamId) {
      return Promise.resolve({
        canUpload: true,
        ...spaceInfo,
      });
    }
    return checkTeamFoldPermission(teamId, parentId).then((canUpload) => {
      if (!canUpload) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'));
      }
      return {
        canUpload: canUpload || false,
        ...spaceInfo,
      };
    });
  };

  // 本次上传为空时走这个逻辑。例：拖拽一个不支持的文件
  matchPatchAndCheckUploadPremossion = (files) => {
    try {
      if (files.length === 0) {
        throw new Error('element null');
      }
      const position = this.getPosition();
      const matchPathInfo = this.getMatchPathInfo();
      let getSpaceInfo = Promise.resolve({
        teamId: 0,
        parentId: 0,
      });

      if (position === 'other') {
        getSpaceInfo = new Promise((reslove) => {
          this.uploadToBack = reslove;
          this.setState({
            showUploadTo: true,
            filesUploadTo: files,
          });
        });
      }

      if (position === 'personal') {
        getSpaceInfo = Promise.resolve({
          teamId: 0,
          parentId: Number(matchPathInfo[1]) || 0,
        });
      }

      if (position === 'team') {
        getSpaceInfo = Promise.resolve({
          teamId: Number(matchPathInfo[1]) || 0,
          parentId: Number(matchPathInfo[2]) || 0,
        });
      }

      return getSpaceInfo.then((spaceInfo) => {
        return this.checkUploadPremossion(spaceInfo);
      });
    } catch (error) {
      if (error.message === 'element null') {
        Modal.error({
          title: intl.t('上传失败'),
          content: intl.t('支持上传的文件个数为0，请重新选择。'),
          okText: intl.t('知道了'),
        });
      }
    }
  };

  hintSupportType = async (forbiddenType) => {
    const strategy = await new Promise((reslove) => {
      TextOnlyModal.confirm({
        title: intl.t(
          '是否继续上传？',
        ),
        content: intl.t(
          '文件夹中存在{num}个文档/文件不支持上传，格式如下：{slot}。若选择“继续上传”，系统会过滤不支持格式的文档。', {
            num: Object.values(forbiddenType).reduce((acc, value) => acc + value, 0),
            slot: Object.keys(forbiddenType).join('/'),
          },
        ),
        type: 'ask',
        okText: intl.t('继续上传'),
        cancelText: intl.t('终止上传'),
        onDestroy: () => {
          reslove(0);
        },
        onOk: () => {
          reslove(1);
        },
        onCancel: () => {
          reslove(2);
        },
      });
    });
    return strategy;
  };

  initIdsPromise = async (files, isDir, isNeedLimitType) => {
    console.log(files, isNeedLimitType, 'files！！！！！');

    const error = getSizeOrAmountStatus(files)({
      maxLength: uploadSize.maxLength,
      maxSize: uploadSize.maxSize,
    });

    try {
      if (!checkFilesByType(error.type)) {
        throw new Error('exceed limit');
      }

      let forbiddenType = {};
      let fileType = '';
      const newFiles = [];
      let hasBigFile = false;

      for (const file of files) {
        let size = BigInt(file.size);
        let max = BigInt(1073741824);
        if (size > max) {
          hasBigFile = true;
        }
        if (getFilePath(file).includes('.DS_Store')) {
          continue;
        }
        // 外部空间中上传文件夹时需要判断文件列表的类型
        if (isNeedLimitType) {
          fileType = file.name?.substring(file.name.lastIndexOf('.'))?.toLowerCase() ?? 'UNKNOWN';
          if (acceptFileSuffixes.includes(fileType)) {
            newFiles.push(file);
          } else {
            forbiddenType[fileType] = !forbiddenType[fileType] ? 1 : forbiddenType[fileType] + 1;
          }
        } else {
          newFiles.push(file);
        }
      }
      const fn = async () => {
        const { teamId, parentId, canUpload } = await this.matchPatchAndCheckUploadPremossion(
          newFiles.map((file) => ({ name: file.name })),
        );
        this.position = {
          teamId,
          parentId,
        };

        if (!canUpload) {
          throw new Error('cancel upload');
        }


        // 上传的文件中包含不支持的文件时走这个逻辑。如：选择/拖拽上传文件夹中包含不支持的文件
        if (isNeedLimitType && JSON.stringify(forbiddenType) !== '{}') {
          await this.hintSupportType(forbiddenType).then((resStrategy) => {
            if (resStrategy === 2) {
              throw new Error('cancel upload');
            }
          });
        }

        const formatStrategyFiles = await this.checkNameAndGetStragy(
          newFiles,
          isDir,
          teamId,
          parentId,
        );
        this.props.switchUploadList(true);
        this.setSyncLock(true);
        this.setState({
          showListSwitch: true,
        });
        this.setState({
          listSwitch: true,
        });
        return this.createTaskAndGetIds(formatStrategyFiles, teamId, parentId);
      };
      // if (hasBigFile) {
      //   return new Promise(
      //     (resolove, reject) => {
      //       Modal.confirm({
      //         width: 480,
      //         className: 'upload-big-file-tip',
      //         title: intl.t('提示'),
      //         content: intl.t(
      //           '检测到您上传的文件中存在大文件（>1G），为提升上传体验，推荐您使用同步备份功能。',
      //         ),
      //         okText: intl.t('使用同步备份'),
      //         cancelText: intl.t('继续上传'),
      //         onOk() {
      //           addRay();
      //           window.open(
      //             'dchat://im/join_channel?uid=181626&token=468cd5f64e6923030bc1ede70a197fe7&id=1392371135961179392',
      //           );
      //           reject();
      //         },
      //         onCancel() {
      //           resolove(fn());
      //         },
      //       });
      //     },
      //     (e) => {
      //       console.log(e);
      //     },
      //   );
      // }
      return fn();
    } catch (err) {
      if (err.message === 'exceed limit') {
        this.handelError(error);
      }
    }
  };

  handelError = (error) => {
    this.props.triggerUploaded(this.position);

    if (
      error.type === uploadError.excess.type
      || error.type === uploadError.oversize.type
    ) {
      Modal.warn({
        title: intl.t('上传失败'),
        content: intl.t(
          '单次文件上传数量不能超过{num}个，请分批上传或压缩成Zip格式再进行上传',
          {
            num: uploadSize.maxLength,
            // size: formatSize(uploadSize.maxSize),
          },
        ),
        okText: intl.t('知道了'),
      });
    }
  };

  switchUploadList = () => {
    this.setState((preSate) => ({
      listSwitch: !preSate.listSwitch,
    }));
  };

  closeAll = (
    isOver,
    { queueing, checking, uploading, paused, failed, success, cancel, amount },
  ) => {
    this.props.triggerUploaded(this.position);

    if (isOver) {
      this.setSyncLock(false);
      this.props.switchUploadList(false);
      this.setState({
        showListSwitch: true,
      });
      this.setState({
        listSwitch: true,
      });
      this.props.uploadCase.cancelAll();
      this.props.uploadCase.reset();
    } else {
      TextOnlyModal.confirm({
        title: intl.t('是否取消全部文件上传？'),
        type: 'ask',
        content: (
          <style type="text/css">
            {`
            .cooper-confirm .ant-checkbox-wrapper {
              margin-bottom: 30px;
            }
          `}
          </style>
        ),

        okText: intl.t('继续上传'),
        cancelText: intl.t('取消上传'),
        onCancel: () => {
          window.__uploadCollect = [];
          this.setSyncLock(false);
          this.props.switchUploadList(false);
          this.setState({
            showListSwitch: true,
          });
          this.setState({
            listSwitch: true,
          });
          this.props.uploadCase.cancelAll();
          this.props.uploadCase.reset(); // 重置重名提示的记住操作

          this.remember = false;
        },
      });
    }
  };

  success = () => {
    this.setSyncLock(false);
    this.props.triggerUploaded(this.position);

    if (!this.syncLock) {
      this.setState({
        listSwitch: false,
      });
      this.setState({
        showListSwitch: false,
      });
      // 成功后不重置上传
      // this.props.uploadCase.reset();
      this.remember = false;
      const endTime = +new Date();
      const { taskPool } = this.props;
      if (window.__uploadCollect && window.__uploadCollect.length > 0) {
        const resultCollect = [];
        const successFileIds = flatten(
          Object.values(taskPool.tasks).map((task) => task.taskItems),
        )
          .filter((taskItem) => taskItem.status.status === 'success')
          .map((taskItem) => taskItem.id);
        window.__uploadCollect.filter((items) => {
          if (successFileIds.includes(items.id)) {
            resultCollect.push({
              ...items,
              endTime,
              limitTime: endTime - items.startTime,
            });
          }
          return !successFileIds.includes(items.id);
        });
        window.__OmegaEvent('ep_webspace_filesupload_success_bt', '', {
          tasksuccess: JSON.stringify(resultCollect),
          taskSuccess: JSON.stringify(resultCollect),
        });
        window.__uploadCollect = [];
      }
      miniBus.dispatchEvent('folderCreated');
    }
  };

  render() {
    const {
      listSwitch,
      showListSwitch,
      showUploadTo,
      filesUploadTo,
      showDrag,
    } = this.state;
    const { taskPool, uploadCase, showList } = this.props;
    return (
      <div className={'cooper-upload-list-up'}>
        <div
          className={`cooper-upload-list ${showList ? 'cooper-upload-list-open' : 'cooper-upload-list-close'}`}
        >
          <Header
            listSwitch={listSwitch}
            showListSwitch={showListSwitch}
            data={taskPool}
            switchUploadList={() => {
              this.switchUploadList();
            }}
            success={this.success}
            closeAll={this.closeAll}
          />

          <Body
            listSwitch={listSwitch}
            data={taskPool}
            uploadCase={uploadCase}
            jumpTo={(position) => {
              this.props.navigate(getPathByIds(position));
            }}
          />
        </div>
        {showUploadTo && (
          <UploadTo
            files={filesUploadTo}
            onClose={() => this.setState({
              showUploadTo: false,
            })
            }
            onUpload={(spaceInfo) => {
              this.setState({
                showUploadTo: false,
              });
              this.uploadToBack(spaceInfo);
            }}
          />
        )}

        <div
          className="drag-modal"
          style={{
            display: showDrag ? 'flex' : 'none',
          }}
        >
          {intl.t('上传文件到当前目录下')}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ UploadList, TeamData }) => {
  const { showList, taskPool, uploadCase } = UploadList;
  const { teamDetail } = TeamData;
  const { cooperate_type } = teamDetail
  return {
    showList,
    taskPool,
    uploadCase,
    cooperate_type,
  };
};

const mapDispatchToProps = ({ UploadList }) => {
  const { switchUploadList, setTaskPool, setUploadCase } = UploadList;
  return {
    switchUploadList,
    setTaskPool,
    setUploadCase,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CooperUploadList));
