import { intl } from 'di18n-react';
import { fromJS } from 'immutable';
import { compose } from 'ramda';
import SparkMD5 from 'spark-md5';
import { uploadContainerStatus, uploadStatus } from '../../util/constants';
import { sliceAndUpload } from '../service/upload.service';
import { abortPromise } from '../../util/abortPromise';
import { getFileSecondsTicket } from '../../util/fileEncode';
import { matchFloatCriticalValue } from '../../util';

const THREADS = 5;

const limitTask2CheckFileSeconds = (taskPool) => (checkFileSeconds) => (limitTask) => {
  taskPool.get('tasks').map((task) => {
    return task.get('taskItems').map((fileItem) => {
      if (limitTask.includes(fileItem.get('id'))) {
        checkFileSeconds(fileItem);
      }
    });
  });
};
const limitTask = (currentLimt) => (task) => {
  const allTask = [];
  task.map((v) => v.map((fileId) => {
    allTask.push(fileId);
    return fileId;
  }));
  const limitTask = fromJS(allTask.slice(0, currentLimt));
  return limitTask;
};

const findQueueTask = (taskPool) => {
  const queueTask = taskPool
    .get('tasks')
    .map((task) => {
      return task
        .get('taskItems')
        .filter((taskItem) => {
          return (
            uploadStatus.queueing.status
            === taskItem.getIn(['status', 'status'])
          );
        })
        .map((taskItem) => {
          return taskItem.get('id');
        });
    })
    .filter((task) => task.size > 0);
  return queueTask;
};

export default class TaskPool {
  constructor(props) {
    this.instance = null;
    this.threads = props.threads;
    this.threadCount = 0;
    this.taskPool = null;
    this.poolBack = null;
    this.hooks = {};
    this.config = {};
    this.initTaskPool = this.initTaskPool.bind(this);
    this.reset = this.reset.bind(this);
    this.getTaskPool = this.getTaskPool.bind(this);
    this.asyncUploadContainer = this.asyncUploadContainer.bind(this);
    this.setTaskPool = this.setTaskPool.bind(this);
    this.registerPoolBack = this.registerPoolBack.bind(this);
    this.getFilePool = this.getFilePool.bind(this);
    this.setFilePool = this.setFilePool.bind(this);
    this.checkHandel = this.checkHandel.bind(this);
    this.updateCurrSumMd5s = this.updateCurrSumMd5s.bind(this);
    this.retry = this.retry.bind(this);
    this.cancel = this.cancel.bind(this);
    this.cancelAll = this.cancelAll.bind(this);
    this.goOn = this.goOn.bind(this);
    this.pause = this.pause.bind(this);
    this.complete = this.complete.bind(this);
    this.setCancel = this.setCancel.bind(this);
    this.checkAndUploadNext = this.checkAndUploadNext.bind(this);
    this.limitFileSliceAndUpload = this.limitFileSliceAndUpload.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.fileSecondsTicket = this.fileSecondsTicket.bind(this);
    this.goCheckFileSeconds = this.goCheckFileSeconds.bind(this);
    this.fileSecondsAndUploadOrUpload = this.fileSecondsAndUploadOrUpload.bind(this);
    this.normalContext = this.normalContext.bind(this);
    this.failedRetryContext = this.failedRetryContext.bind(this);
    this.retryContext = this.retryContext.bind(this);
    this.handleUploadOrFailed = this.handleUploadOrFailed.bind(this);
    this.limitFileSeconds = this.limitFileSeconds.bind(this);
    this.limitToUploadTwo = this.limitToUploadTwo.bind(this);
    this.limitToUpload = this.limitToUpload.bind(this);
  }

  static getInstance(threads, hook = () => {}) {
    if (!this.instance) {
      this.instance = new TaskPool({ threads: threads || THREADS, hook });
    }
    return this.instance;
  }

  initTaskPool() {
    if (!this.taskPool) {
      this.taskPool = fromJS({
        progress: 0,
        status: uploadContainerStatus.checking,
        tasks: {},
      });
    } else if (this.taskPool.get('tasks').size > 0) {
      this.taskPool = this.taskPool.set('status', uploadContainerStatus.addTo);
    } else {
      this.taskPool = this.taskPool.set(
        'status',
        uploadContainerStatus.checking,
      );
    }
    return this.taskPool;
  }

  reset() {
    this.taskPool = fromJS({
      progress: 0,
      status: uploadContainerStatus.checking,
      tasks: {},
    });
    this.threadCount = 0;
    this.poolBack(this.taskPool);
  }

  getTaskPool() {
    return this.taskPool;
  }

  asyncUploadContainer(pool) {
    let queueing = 0;
    let checking = 0;
    let uploading = 0;
    let paused = 0;
    let failed = 0;
    let success = 0;
    let cancel = 0;
    let amount = 0;
    pool.get('tasks').map((task) => {
      task.get('taskItems').map((fileItem) => {
        amount += 1;
        switch (fileItem.getIn(['status', 'status'])) {
          case uploadStatus.queueing.status:
            queueing += 1;
            break;
          case uploadStatus.checking.status:
            checking += 1;
            break;
          case uploadStatus.uploading.status:
            uploading += 1;
            break;
          case uploadStatus.paused.status:
            paused += 1;
            break;
          case uploadStatus.failed.status:
            failed += 1;
            break;
          case uploadStatus.success.status:
            success += 1;
            break;
          case uploadStatus.cancelled.status:
            cancel += 1;
            break;
          default:
            break;
        }
      });
    });
    pool = pool.set('count', {
      queueing,
      checking,
      uploading,
      paused,
      failed,
      success,
      cancel,
      amount,
    });
    pool = pool.set('progress', matchFloatCriticalValue(success / amount));
    const nowStatus = pool.getIn(['status', 'status']);
    if (
      nowStatus !== uploadContainerStatus.uploading.status
      && queueing >= 0
      && uploading + checking > 0
    ) {
      pool = pool.set('status', uploadContainerStatus.uploading);
      this.poolBack(pool);
    }
    if (nowStatus === uploadContainerStatus.checking.status) {
      return pool;
    }
    if (
      nowStatus !== uploadContainerStatus.paused.status
      && paused > 0
      && uploading + checking === 0
      && failed === 0
    ) {
      pool = pool.set('status', uploadContainerStatus.paused);
      this.poolBack(pool);
    }
    if (
      nowStatus !== uploadContainerStatus.success.status
      && uploading + checking === 0
      && paused === 0
      && failed === 0
      && cancel + success > 0
    ) {
      pool = pool.set('status', uploadContainerStatus.success);
      this.poolBack(pool);
    }
    if (
      nowStatus !== uploadContainerStatus.failed.status
      && failed > 0
      && uploading + checking === 0
    ) {
      pool = pool.set('status', uploadContainerStatus.failed);
      this.poolBack(pool);
    }
    return pool;
  }

  setTaskPool(pool) {
    this.taskPool = this.asyncUploadContainer(pool);
    return this.taskPool;
  }

  registerPoolBack(cb) {
    if (!this.poolBack) {
      this.poolBack = cb;
    }
  }

  getFilePool(fileId) {
    return (keyArr) => {
      let data = null;
      this.taskPool.get('tasks').map((task) => {
        task.get('taskItems').map((fileItem) => {
          if (fileItem.get('id') === fileId) {
            data = fileItem;
          }
        });
      });
      return data.getIn(keyArr);
    };
  }

  setFilePool(fileId) {
    return (keyArr, value) => {
      const newPool = this.taskPool.update('tasks', (tasks) => {
        return tasks.map((task) => {
          return task.update('taskItems', (taskItems) => {
            return taskItems.map((taskItem) => {
              if (taskItem.get('id') === fileId) {
                taskItem = taskItem.setIn(keyArr, value);
              }
              return taskItem;
            });
          });
        });
      });
      this.setTaskPool(newPool);
    };
  }

  checkHandel(fileItem) {
    if (
      this.taskPool.get('tasks').size === 0
      || ![
        uploadStatus.queueing.status,
        uploadStatus.checking.status,
        uploadStatus.uploading.status,
      ].includes(this.getFilePool(fileItem.get('id'))(['status', 'status']))
    ) {
      throw new Error('abort');
    }
  }

  retry(fileItem) {
    if (this.threadCount < this.threads) {
      this.retryContext(fileItem);
    } else {
      this.setFilePool(fileItem.get('id'))(
        ['status'],
        fromJS(uploadStatus.queueing),
      );
      this.poolBack(this.taskPool);
    }
  }

  cancel(fileItem) {
    let isUploadingCancel = [
      uploadStatus.uploading.status,
      uploadStatus.paused.status,
    ].includes(fileItem.getIn(['status', 'status']));
    // 文件从上传状态/暂停状态取消，认为文件被取消---用来计算用户主动取消的文件数
    if (isUploadingCancel) {
      window.__OmegaEvent('ep_upload_cancel_ck');
      if (window.uploadCount) {
        window.uploadCount.cancel++;
      }
      console.log(window.uploadCount.cancel, intl.t('取消+1'));
    }
    const fn = this.getFilePool(fileItem.get('id'))(['fn']);
    if (fn && fn.abort) {
      this.getFilePool(fileItem.get('id'))(['fn']).abort();
    }
    if (
      [uploadStatus.uploading.status, uploadStatus.checking.status].includes(
        this.getFilePool(fileItem.get('id'))(['status', 'status']),
      )
    ) {
      this.threadCount -= 1;
    }
    this.setCancel(fileItem.get('id'));
  }

  cancelAll(cb = () => {}) {
    let handelAmount = 0;
    this.taskPool.get('tasks').map((task) => {
      task.get('taskItems').map((fileItem) => {
        if (
          [
            uploadStatus.queueing.status,
            uploadStatus.checking.status,
            uploadStatus.uploading.status,
            uploadStatus.paused.status,
          ].includes(fileItem.getIn(['status', 'status']))
        ) {
          this.cancel(fileItem);
          handelAmount += 1;
        }
      });
    });
    if (handelAmount > 0) {
      return this.cancelAll(cb);
    }
    return cb();
  }

  goOn(fileItem) {
    this.retry(fileItem);
  }

  pause(fileItem) {
    const fn = this.getFilePool(fileItem.get('id'))(['fn']);
    if (fn && fn.abort) {
      this.getFilePool(fileItem.get('id'))(['fn']).abort();
    }
    this.threadCount -= 1;
    this.setFilePool(fileItem.get('id'))(
      ['status'],
      fromJS(uploadStatus.paused),
    );
    this.poolBack(this.taskPool);
  }

  complete(fileId) {
    const newPool = this.taskPool.update('tasks', (tasks) => {
      return tasks.map((task) => {
        return task.update('taskItems', (taskItems) => {
          return taskItems.map((taskItem) => {
            if (taskItem.get('id') === fileId) {
              taskItem = taskItem
                .set('status', fromJS(uploadStatus.success))
                .set('progress', 100);
            }
            return taskItem;
          });
        });
      });
    });
    this.setTaskPool(newPool);
    this.poolBack(this.taskPool);
  }

  setCancel(fileId) {
    const newPool = this.taskPool.update('tasks', (tasks) => {
      return tasks.map((task) => {
        return task.update('taskItems', (taskItems) => {
          return taskItems.map((taskItem) => {
            if (taskItem.get('id') === fileId) {
              taskItem = taskItem
                .set('status', fromJS(uploadStatus.cancelled))
                .set('progress', 0)
                .set('exist', null)
                .set('retry', 0)
                .set('secondsTicket', null)
                .set('fn', null)
                .set('seqId', 0);
            }
            return taskItem;
          });
        });
      });
    });
    this.setTaskPool(newPool);
    this.poolBack(this.taskPool);
  }

  checkAndUploadNext() {
    if (findQueueTask(this.taskPool).size > 0) {
      this.limitToUploadTwo();
    }
  }

  updateCurrSumMd5s(currBufferMd5, fileItem, seqId) {
    let fileId = fileItem.get('id');

    if (
      this.config.sumMd5s[fileId]
      && this.config.sumMd5s[fileId].sliceIndex
      && this.config.sumMd5s[fileId].sliceIndex.indexOf(seqId) === -1
    ) {
      this.config.sumMd5s[fileId].md5Array.push(currBufferMd5);
      this.config.sumMd5s[fileId].sliceIndex.push(seqId);
    }
  }

  limitFileSliceAndUpload(fileItem, seqId = 0, worker) {
    const chunks = fileItem.get('file').size > this.config.sliceBoundary
      ? Math.ceil(fileItem.get('file').size / this.config.sliceSize)
      : 1;
    const start = seqId * this.config.sliceSize;
    const end = start + this.config.sliceSize >= fileItem.get('file').size
      || fileItem.get('file').size <= this.config.sliceBoundary
      ? fileItem.get('file').size
      : start + this.config.sliceSize;
    return sliceAndUpload(
      fileItem,
      start,
      end,
      seqId,
      worker,
    )({
      isSliceSeconds: this.config.isSliceSeconds,
      sliceSeconds: this.hooks.sliceSeconds,
      sliceUpload: this.hooks.sliceUpload,
    })(this.setFilePool, this.checkHandel, this.updateCurrSumMd5s).then(
      (response) => {
        seqId += 1;
        if (seqId < chunks) {
          this.setFilePool(fileItem.get('id'))(['seqId'], seqId);
          const preProgress = this.getFilePool(fileItem.get('id'))(['progress']) || 0;
          const nowProgress = matchFloatCriticalValue(seqId / chunks) || 0;
          this.setFilePool(fileItem.get('id'))(
            ['progress'],
            preProgress > nowProgress ? preProgress : nowProgress,
          );
          this.poolBack(this.taskPool);
          return this.limitFileSliceAndUpload(fileItem, seqId, worker);
        }
        return response;
      },
    );
  }

  fileUpload(fileItem) {
    let fileId = fileItem.get('id');
    if (!this.config.sumMd5s[fileId]) {
      this.config.sumMd5s[fileId] = {
        md5Array: [],
        sliceIndex: [],
      };
    }
    const seqId = this.getFilePool(fileItem.get('id'))(['seqId']);
    this.setFilePool(fileItem.get('id'))(
      ['progress'],
      this.getFilePool(fileItem.get('id'))(['progress']) || 1,
    );
    this.setFilePool(fileItem.get('id'))(
      ['status'],
      fromJS(uploadStatus.uploading),
    );
    this.poolBack(this.taskPool);
    const worker = '';
    return this.limitFileSliceAndUpload(fileItem, seqId, worker).then(() => {
      this.checkHandel(fileItem);
      let fn = abortPromise(
        new Promise((resolve) => {
          const sliceMD5Util = new SparkMD5();
          if (this.config.sumMd5s[fileId].md5Array.length > 0) {
            this.config.sumMd5s[fileId].md5Array.forEach((i) => {
              sliceMD5Util.append(i);
            });
          }
          resolve(sliceMD5Util.end());
        }),
      );
      this.setFilePool(fileItem.get('id'))(['fn'], fn);
      return fn.then((fileMd5) => {
        return this.hooks.fileComplete({
          fileId: fileItem.get('id'),
          md5: fileMd5,
          totalMd5: '',
          token: fileItem.get('token'),
        });
      });
    });
  }

  fileSecondsTicket(fileItem) {
    const secondsTicket = this.getFilePool(fileItem.get('id'))([
      'secondsTicket',
    ]);
    if (secondsTicket) {
      return abortPromise(Promise.resolve(secondsTicket));
    }
    this.checkHandel(fileItem);
    const syncProgress = (progress, worker) => {
      if (
        this.taskPool.get('tasks').size === 0
        || ![
          uploadStatus.queueing.status,
          uploadStatus.checking.status,
          uploadStatus.uploading.status,
        ].includes(this.getFilePool(fileItem.get('id'))(['status', 'status']))
      ) {
        worker && worker.terminate();
      } else if (
        progress > this.getFilePool(fileItem.get('id'))(['progress'])
      ) {
        this.setFilePool(fileItem.get('id'))(['progress'], progress);
        this.poolBack(this.taskPool);
      }
    };
    let fn = abortPromise(getFileSecondsTicket(fileItem, syncProgress));
    this.setFilePool(fileItem.get('id'))(['fn'], fn);
    fn.then((ticket) => {
      this.setFilePool(fileItem.get('id'))(['secondsTicket'], ticket);
      return ticket;
    });
    return fn;
  }

  goCheckFileSeconds(fileItem) {
    this.checkHandel(fileItem);
    let fn = abortPromise(this.fileSecondsTicket(fileItem));
    this.setFilePool(fileItem.get('id'))(['fn'], fn);
    return fn.then((ticket) => {
      this.checkHandel(fileItem);
      fn = this.hooks.checkFileSecondsFn(ticket.toJS());

      this.setFilePool(fileItem.get('id'))(['fn'], fn);

      return fn.then((exist) => {
        this.setFilePool(fileItem.get('id'))(['exist'], fromJS(exist));
        if (exist.exist) {
          this.complete(fileItem.get('id'));
          this.threadCount -= 1;
          this.checkAndUploadNext();
          return exist;
        }
        return this.fileUpload(fileItem).then((res) => {
          this.complete(fileItem.get('id'));
          this.threadCount -= 1;
          this.checkAndUploadNext();
          return res;
        });
      });
    });
  }

  fileSecondsAndUploadOrUpload(fileItem) {
    const isCheckedFileSeconds = this.getFilePool(fileItem.get('id'))([
      'exist',
    ]);
    if (this.config.isCheckFileSeconds && !isCheckedFileSeconds) {
      return this.goCheckFileSeconds(fileItem);
    }
    let fn = abortPromise(this.fileSecondsTicket(fileItem));
    // DONE: 重试的时候走的这个逻辑没有传totalmd5
    return fn.then((ticket) => {
      return this.fileUpload(fileItem).then((res) => {
        this.complete(fileItem.get('id'));
        this.threadCount -= 1;
        this.checkAndUploadNext();
        return res;
      });
    });
  }

  normalContext(fileItem) {
    const nowStatus = this.getFilePool(fileItem.get('id'))([
      'status',
      'status',
    ]);
    if (nowStatus !== uploadStatus.queueing.status) {
      this.checkAndUploadNext();
    } else {
      this.threadCount += 1;
      this.setFilePool(fileItem.get('id'))(
        ['status'],
        fromJS(uploadStatus.checking),
      );
      this.poolBack(this.taskPool);
      this.handleUploadOrFailed(fileItem);
    }
  }

  failedRetryContext(fileItem) {
    const nowStatus = this.getFilePool(fileItem.get('id'))([
      'status',
      'status',
    ]);
    if (
      [uploadStatus.checking.status, uploadStatus.uploading.status].includes(
        nowStatus,
      )
    ) {
      this.handleUploadOrFailed(fileItem);
    } else {
      this.checkAndUploadNext();
    }
  }

  retryContext(fileItem) {
    const nowStatus = this.getFilePool(fileItem.get('id'))([
      'status',
      'status',
    ]);
    if (
      ![
        uploadStatus.cancelled.status,
        uploadStatus.failed.status,
        uploadStatus.paused.status,
      ].includes(nowStatus)
    ) {
      this.checkAndUploadNext();
    } else {
      this.threadCount += 1;
      this.setFilePool(fileItem.get('id'))(
        ['status'],
        fromJS(uploadStatus.checking),
      );
      this.poolBack(this.taskPool);
      this.handleUploadOrFailed(fileItem);
    }
  }

  handleUploadOrFailed(fileItem) {
    this.checkHandel(fileItem);
    let fn = abortPromise(this.fileSecondsAndUploadOrUpload(fileItem));
    this.setFilePool(fileItem.get('id'))(['fn'], fn);
    return fn.catch((err) => {
      if (
        (err.name && err.name.toLocaleLowerCase().includes('abort'))
        || err.toString().toLocaleLowerCase().includes('abort')
      ) {
        console.log(err);
      } else {
        const retry = this.getFilePool(fileItem.get('id'))(['retry']);
        if (retry < 5) {
          this.setFilePool(fileItem.get('id'))(['retry'], retry + 1);
          this.failedRetryContext(fileItem);
        } else {
          this.setFilePool(fileItem.get('id'))(
            ['status'],
            fromJS(uploadStatus.failed),
          );
          this.poolBack(this.taskPool);
          this.threadCount -= 1;
          this.checkAndUploadNext();
          console.log(err);
        }
      }
    });
  }

  limitFileSeconds = (currentLimt) => {
    return compose(
      limitTask2CheckFileSeconds(this.taskPool)(this.normalContext),
      limitTask(currentLimt),
      findQueueTask,
    );
  };

  limitToUploadTwo() {
    if (this.threadCount < this.threads) {
      this.limitFileSeconds(this.threads - this.threadCount)(this.taskPool);
    }
  }

  limitToUpload({ hooks, config }) {
    this.hooks = hooks;
    this.config = config;
    this.limitToUploadTwo();
  }
}
