import { Skeleton } from 'antd';
import classBind from 'classnames/bind';
import React from 'react';
import styles from './AsideLargeSkeleton.less';

const cx = classBind.bind(styles);


const SkeletonAsideItem = () => <div className={cx('aside-skeleton-box-item')}>
  <div className={cx('aside-skeleton-logo aside-skeleton-button')}>
    <Skeleton.Button shape='square' />
  </div>
  <div className={cx('aside-skeleton-title aside-skeleton-button')}>
    <Skeleton.Button
      active={true}
      shape='round' />
  </div>
</div>


const SkeletonAsideTeamItem = () => <div className={cx('aside-skeleton-space-item')}>
  <div className={cx('aside-skeleton-symbol aside-skeleton-button')} >
    <Skeleton.Button shape='circle' />
  </div>
  <div className={cx('aside-skeleton-title aside-skeleton-button')} >
    <Skeleton.Button
      active={true}
      shape='round' />
  </div>
</div>


export default React.memo(() => {
  const arr3 = new Array(3).fill(0)
  const arr5 = new Array(5).fill(0)
  return <div className={cx('aside-skeleton-box')}>
    {arr3.map(() => (<SkeletonAsideItem />))}
    <div className={cx('aside-skeleton-space')}>
      {arr5.map(() => (<SkeletonAsideTeamItem />))}
    </div>
    {arr5.map(() => (<SkeletonAsideItem />))}
  </div >
})


