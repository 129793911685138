import TeamSkeleton from '../common/TeamSkeleton.js';
import replaceIntl from '../utils/wrapWithIntl.js';

const teamWithIntl = replaceIntl(TeamSkeleton);// 实际上没有文案，仅统一写法

// 没有header，body本身就是common中的组件
const renderStr = /* html */`
${teamWithIntl}
`

export default renderStr
