/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-27 15:07:09
 * @LastEditTime: 2024-03-20 10:19:23
 * @Description: 侧边栏或者头部中的小图标群：登录、消息、帮助中心等
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/IconFamily/index.js
 *
 */
// 侧边栏或者头部中的小图标群：登录、消息、帮助中心等
// import { useEffect, useState } from 'react';
import CooperNotify from './CooperNotify';
import UserInfo from './UserInfo';
import CooperLibrary from './CooperLibrary';
// import CooperHelper from '../../GlobalUtils/CooperHelper';
import CooperTip from './CooperTip';
import { isDC } from '@/utils';
import { entryEnhance } from '@/utils/entryEnhance'


function IconFamily({ isLarge = true }) {
  return (
    <>
      {/* <CooperHelper type={isLarge ? 'large' : 'small'} /> */}
      {
        !isDC() && <UserInfo type={isLarge ? 'large' : 'small'} />
      }
      <CooperNotify type={isLarge ? 'large' : 'small'} />
      {entryEnhance(<CooperLibrary type={isLarge ? 'large' : 'small'} />)}
      {/* {entryEnhance(isDC() && <CooperLibrary type={isLarge ? 'large' : 'small'} />)} */}
      {entryEnhance(<CooperTip type={isLarge ? 'large' : 'small'} />)}
    </>
  );
}

export default IconFamily;
