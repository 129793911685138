import pLimit from './pLimit.js';
import {
  filePPT,
  fileDoc,
  fileExcel,
  unknown,
  filePdf,
  video,
  pic,
  folder,
  fileTxt,
  fileZip,
} from '@/assets/icon/fileIcon';

export const fileIcons = {
  // 文件夹
  folder,
  // 文件
  picture: pic,
  video,
  pdf: filePdf,
  doc: fileDoc,
  ppt: filePPT,
  xls: fileExcel,
  txt: fileTxt,
  zip: fileZip,
  // 未知类型
  unknown,
};

// 文件类型
export const fileTypes = {
  word: 'word',
  excel: 'excel',
  txt: 'txt',
  image: 'image',
  folder: 'DIR',
  other: 'other',
};

export const getIconByName = (name, type) => {
  if (type === fileTypes.folder) return fileIcons.folder;
  const lowerName = name.toLowerCase();
  if (/\.pdf$/.test(lowerName)) return fileIcons.pdf;
  if (/\.docx?$/.test(lowerName)) return fileIcons.doc;
  if (/\.pptx?$/.test(lowerName)) return fileIcons.ppt;
  if (/\.xlt$|\.xlsx?$|\.xml$|\.numbers$/.test(lowerName)) return fileIcons.xls;
  if (/\.txt$|\.md$/.test(lowerName)) return fileIcons.txt;
  if (/\.zip$|\.tar$|\.gz$/.test(lowerName)) return fileIcons.zip;
  if (/\.mp3$/.test(lowerName)) return fileIcons.video;
  if (/\.mp4$|\.avi$|\.wma$|\.3gp$|\.mov$|\.ogg$|\.rmvb$/.test(lowerName)) return fileIcons.video;
  if (/\.jpe?g$|\.gif$|\.png$|\.svg$/.test(lowerName)) return fileIcons.picture;
  return fileIcons.unknown;
};

/**
  * @params filterName
  * @return  word - text , excel - spreadsheet, ppt｜pptx - presentation , pdf - pdf , 视频 - video , 其他格式。
  * */
export const checkFileType = (filterName) => {
  let index = filterName.lastIndexOf('.');
  let fileValueSuffix = filterName.substring(index);
  if (/(.*)\.(mp4|avi|wmv|wma|3gp|mov|ogg|rmvb|rm|asf|mpg|mpeg|mpe|wmv|mkv|vob)$/.test(fileValueSuffix)) {
    return 'video';
  } if (/(.*)\.(doc|docx)$/.test(fileValueSuffix)) {
    return 'text';
  } if (/(.*)\.(xlsx|xls)$/.test(fileValueSuffix)) {
    return 'spreadsheet';
    // 待确认pptx 属于吗 presentation 类型吗？
  } if (/(.*)\.(ppt|pptx)$/.test(fileValueSuffix)) {
    return 'presentation';
  } if (/(.*)\.(pdf)$/.test(fileValueSuffix)) {
    return 'pdf';
  }
  return fileValueSuffix;
};


const checkTiny = (tiny) => !!tiny;

const setTinyStyle = (tinyImg) => (localPx) => {
  tinyImg.style.bottom = 'unset';
  tinyImg.style.top = localPx;
};

const showTinyImg = (tinyImg) => {
  tinyImg.style.display = 'block';
};

const hideTinyImg = (tinyImg) => {
  tinyImg.style.display = 'none';
};

export const enlarge = (record) => (tinyImg) => (localPx) => {
  if (!checkTiny(record.tiny)) return;
  showTinyImg(tinyImg);
  setTinyStyle(tinyImg)(localPx);
};

export const narrow = (record) => (tinyImg) => {
  if (!checkTiny(record.tiny)) return;
  hideTinyImg(tinyImg);
};

export const isDC = () => Boolean(window.dcJSSDK);

export const getBussinessIds = (parentLocationUrl) => {
  const pathname = parentLocationUrl || window.location.pathname;
  // 个人空间
  if (/^\/disk\/|^\/files\//.test(pathname)) {
    const m = pathname.match(/^\/files\/(\d+)$/);
    const parentId = m ? Number(m[1]) : 0;
    return { parentId, teamId: 0 };
  }

  // 团队空间
  if (/^\/team-file\//.test(pathname)) {
    const m1 = pathname.match(/^\/team-file\/\d+\/(\d+)$/);
    const parentId = m1 ? Number(m1[1]) : 0;
    const m2 = pathname.match(/^\/team-file\/(\d+)/);
    const teamId = m2 ? Number(m2[1]) : 0;
    return { parentId, teamId };
  }

  // 其他页面
  return { parentId: 0, teamId: 0 };
};

export const getFilePath = (file) => (file.fullPath || file.webkitRelativePath || file.name);

export const PLimit = pLimit;

export const getPathByIds = ({ teamId, parentId }) => {
  let path = '';
  if (Number(teamId) === 0) {
    if (Number(parentId) === 0) {
      path = '/disk';
    } else {
      path = `/files/${parentId}`;
    }
  } else if (Number(parentId) === 0) {
    path = `/team-file/${teamId}`;
  } else {
    path = `/team-file/${teamId}/${parentId}`;
  }
  return path;
};
