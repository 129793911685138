/* eslint-disable prefer-template */
/* NOTE 超过一个变量时，不可使用模版字符串！ */
const QuickAccessSkeleton = require('../../common/QuickAccessSkeleton');
const FileTableSkeleton = require('../../common/FileTableSkeleton');
const HomeBannerSkeleton = require('../../common/HomeBannerSkeleton');

const ret = /* html */
  `<div class="body-wrap">`
  + HomeBannerSkeleton
  + `<div class="home-skeleton-wrap">`
  + QuickAccessSkeleton /* html */
  + `<div class="recent-act-skeleton">
      <div class="tab-skeleton">
        <div class="ant-tabs ant-tabs-top ant-tabs-middle tab-top-title tab-top-large" tabsize="large">
          <div role="tablist" class="ant-tabs-nav">
            <div class="ant-tabs-nav-wrap">
              <div class="ant-tabs-nav-list" style="transform: translate(0px, 0px);">
                <div class="ant-tabs-tab ant-tabs-tab-active">
                  <div role="tab" aria-selected="true" class="ant-tabs-tab-btn" tabindex="0" id="rc-tabs-0-tab-1" aria-controls="rc-tabs-0-panel-1">
                    <span>
                      <span>最近访问</span></span>
                  </div>
                </div>
                <div class="ant-tabs-tab">
                  <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="0" id="rc-tabs-0-tab-2" aria-controls="rc-tabs-0-panel-2">
                    <span>
                      <span>最近编辑</span></span>
                  </div>
                </div>
                <div class="ant-tabs-ink-bar ant-tabs-ink-bar-animated" style="left: 0px; width: 64px;"></div>
              </div>
            </div>
            <div class="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
              <button type="button" class="ant-tabs-nav-more" tabindex="-1" aria-hidden="true" aria-haspopup="listbox" aria-controls="rc-tabs-0-more-popup" id="rc-tabs-0-more" aria-expanded="false" style="visibility: hidden; order: 1;">
                <span role="img" aria-label="ellipsis" class="anticon anticon-ellipsis">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="ellipsis" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div class="ant-tabs-content-holder">
            <div class="ant-tabs-content ant-tabs-content-top">
              <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active" id="rc-tabs-0-panel-1" aria-labelledby="rc-tabs-0-tab-1">`
  + FileTableSkeleton /* html */
  + `</div>
              <div role="tabpanel" tabindex="-1" aria-hidden="true" class="ant-tabs-tabpane" id="rc-tabs-0-panel-2" aria-labelledby="rc-tabs-0-tab-2" style="display: none;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>`

module.exports = ret
