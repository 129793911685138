import { getLocale, intl } from 'di18n-react';
import { isDiDiTenant } from '@/utils/entryEnhance'
import GuideModal from '../GuideModal';
import step1US from './gif/1-us.png';
import step1 from './gif/1.png';
import step2US from './gif/2-us.png';
import step2 from './gif/2.png';
import step3 from './gif/3.png';
import step3US from './gif/3US.png';
import step4US from './gif/4-us.png';
import step4 from './gif/4.png';
import step5US from './gif/5-us.png';
import step5 from './gif/5.png';
import step6US from './gif/6-us.png';
import step6 from './gif/6.png';

function NewCooperGuide() {
  const isUS = getLocale() === 'en-US';

  const data = [
    {
      title: 'Cooper搜索能力强势升级',
      img: isUS ? step1US : step1,
      summary: '支持全文内容检索、团队空间检索，新增知识库、Wiki的检索数据来源，筛选能力多样化，提升搜索触达效率和体验！',
    },
    {
      title: '协作文档/表格升级2.0新版本',
      img: isUS ? step2US : step2,
      summary: '编辑、协作功能强化——支持跨表引用、多级下拉菜单、锁定单元格/表、保存版本等能力。离线缓存能力增强，网络不好也不怕！',
    },
    {
      title: '团队空间有了自己的回收站',
      img: isUS ? step3US : step3,
      summary: '团队空间内被删除的文档会进入文档所在空间内的回收站，30天内支持文档所有者或空间管理员恢复至原位置！',
    },
    {
      title: '知识库移动端消费升级啦',
      img: isUS ? step4US : step4,
      summary: '云空间内新增知识库入口，强化了移动端知识消费能力，支持查看目录、收藏和分享知识库页面。',
    },
    {
      title: '最近、收藏使用场景优化',
      img: isUS ? step5US : step5,
      summary: '最近访问/编辑新增Wiki和知识库数据、收藏模块新增知识库数据，统一司内知识消费路径，帮助知识库快速寻回。',
    },
    {
      title: 'Cooper新视觉新面貌',
      img: isUS ? step6US : step6,
      summary: '新版Cooper平台提供了全新的视觉感受，屏效更高，增加内容露出，小屏电脑体验更加友好！',
    },
  ];

  const tenantData = [
    {
      title: 'Cooper搜索能力强势升级',
      img: isUS ? step1US : step1,
      summary: '支持全文内容检索、团队空间检索，筛选能力多样化，提升搜索触达效率和体验！',
    },
    {
      title: '协作文档/表格升级2.0新版本',
      img: isUS ? step2US : step2,
      summary: '编辑、协作功能强化——支持跨表引用、多级下拉菜单、锁定单元格/表、保存版本等能力。离线缓存能力增强，网络不好也不怕！',
    },
    {
      title: '团队空间有了自己的回收站',
      img: isUS ? step3US : step3,
      summary: '团队空间内被删除的文档会进入文档所在空间内的回收站，30天内支持文档所有者或空间管理员恢复至原位置！',
    },
    {
      title: 'Cooper新视觉新面貌',
      img: isUS ? step6US : step6,
      summary: '新版Cooper平台提供了全新的视觉感受，屏效更高，增加内容露出，小屏电脑体验更加友好！',
    },
  ];
  return <GuideModal
    config={isDiDiTenant() ? data : tenantData}
    finishText={intl.t('开启Cooper平台新体验')}
    userViewKey= {'GUIDE_DIALOG'}
  />;
}

export default NewCooperGuide;
