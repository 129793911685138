/* eslint-disable camelcase */
/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-01 16:07:48
 * @LastEditTime: 2023-09-04 16:47:54
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/index.js
 *
 */
import { COOPER_ASIDE_TYPE } from '@/constants/index';
import { Tooltip } from 'antd';
import cls from 'classnames';
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Aside from './Aside';
import Header from './Header';
// import { handleScrollTable } from '@/components/RecentActivity/index';
import GlobalUtils from '@/components/GlobalUtils/index';
import NoviceGuide from '@/components/NoviceGuide';
import CooperUploadList from '@/components/Upload/CooperUploadList';
import useGlobalInit from '@/hooks/useGlobalInit';
// import { getApolloConfig } from '@/utils/ab';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalData, setLocalData } from '@/utils/localStorage';
import { isDiDiTenant } from '@/utils/entryEnhance';
import errorNotice from '../LiteModal';
import styles from './style.module.less';

const cx = classBind.bind(styles);

function LayoutCooper() {
  useGlobalInit();
  // const [initLibrary, setInitLibrary] = useState(false);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const {
    setAsideType,
    setUserViewDataRq,
    setToastPersonTrashTip,
    setToastTeamTrashTip,
    setNeedAsideSkeleton,
    getUnreadList,
    getTenantDataRq,
  } = dispatch.GlobalData;
  const { userViewData, asideType } = useSelector((state) => state.GlobalData);
  const [position, setPosition] = useState(
    JSON.stringify(getLocalData('SATISFY_SURVEY')) !== '{}'
      ? getLocalData('SATISFY_SURVEY')
      : 160,
  );

  const [, setUploadFileObj] = useState(null);
  const [uploadEdArgs, setUploadEdArgs] = useState(null);
  // const [ surveyInfo, setSurveyInfo ] = useState({
  //   open: false,
  //   url: null,
  // })

  // const isSwitchToLite = client?.boolValue('switch_to_lite', false);
  // const isServiveNotAvaliable = client?.boolValue('error_notice', false);

  // useEffect(() => {
  //   if (isServiveNotAvaliable && window.location.pathname !== '/send-file') {
  //     errorNotice(1);
  //   }
  // }, [isServiveNotAvaliable, location.pathname]);

  useEffect(async () => {
    // apolloSwitch(LibraryCooper).then((res) => {
    //   setInitLibrary(res)
    // });
    getUnreadList();
    // getApolloConfig('cooper_satisfaction_survey').then(res => {
    //   setSurveyInfo(res);
    // });
  }, [])

  // const [, drop] = useDrop(() => ({
  //   accept: 'box',
  //   drop: (item, monitor) => {
  //     const delta = monitor.getClientOffset();
  //     let top = delta.y;

  //     if (top > document.body.clientHeight - 48) {
  //       top = document.body.clientHeight - 48;
  //     }

  //     if (top < 24) {
  //       top = 24;
  //     }

  //     setPosition(top - 24 );
  //     setLocalData('SATISFY_SURVEY', top - 24)
  //   },
  //   collect: (monitor) => ({
  //     isOver: monitor.isOver(),
  //     canDrop: monitor.canDrop(),
  //   }),
  // }))

  useEffect(() => {
    const { New_Trash_Person_Close, New_Trash_Team_Close } = userViewData;
    if (New_Trash_Person_Close === false) {
      setTimeout(() => {
        setToastPersonTrashTip(New_Trash_Person_Close === false);
      }, 1000);
    }
    setToastTeamTrashTip(New_Trash_Team_Close === false);
  }, [userViewData?.New_Trash_Person_Close, userViewData?.New_Trash_Team_Close]);

  useEffect(() => {
    if (uploadEdArgs) {
      setUploadEdArgs(null);
    }
  }, [uploadEdArgs]);

  const changeType = () => {
    const value = 3 - asideType;
    setNeedAsideSkeleton(false);
    setAsideType(value);
    setLocalData('asideType', value);

    let data = { ...userViewData };
    data.Aside_Type_Cooper = value;
    if (JSON.stringify(userViewData) === '{}') return;
    setUserViewDataRq(data);
  };

  const triggerUploaded = (opt) => {
    if (!opt) {
      setUploadFileObj(null);
    }
    setUploadEdArgs(opt);
  };

  const isDkShare = pathname.indexOf('share') !== -1 && pathname.indexOf('knowledge') !== -1;

  return (
    <div className={`${cx('layout')} ${isDiDiTenant() ? cx('diditenant') : ''}`}>
      {!isDkShare && (
        <div className={`${cls('left-wrap')} ${cx('left-wrap')}`}>
          <div className={cx('left', 'os-scrollbar')}>
            <Aside />
          </div>
          <div className={cx('aside-btn-handle')}>
            <Tooltip
              title={
                asideType === COOPER_ASIDE_TYPE.LARGE
                  ? intl.t('收起')
                  : intl.t('展开')
              }
              placement="top"
            >
              <div
                className={cx('aside-operate', {
                  isZhankai: asideType === COOPER_ASIDE_TYPE.SMALL,
                })}
                onClick={changeType}
              >
                <i
                  className={cx(
                    'dk-iconfont',
                    'dk-icon-zhedieshouqidejiantou',
                    'operate-icon',
                  )}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      )}

      <div className={cx('right')}>
        <div className={cx('header-wrap')}>
          <Header />
        </div>
        <div
          className={cx('body-wrap')}
        // onScroll={(e) => { if (pathname === '/') { handleScrollTable(e) } }}
        >
          <Outlet />
        </div>
      </div>
      <CooperUploadList triggerUploaded={triggerUploaded} />
      <GlobalUtils
        surveyInfo={{}}
      />
      { isDiDiTenant() && <NoviceGuide/> }
    </div>
  );
}

export default LayoutCooper;
