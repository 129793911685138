import React from 'react';
import { intl } from 'di18n-react';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { openSyncDisk } from './dc-helper';
import cooperConfirm from '@/components/common/CooperConfirm';
import { useSelector, useDispatch } from 'react-redux';
import syncDisk from './icons/syncDisk.svg';
import styles from './style.module.less';

const cx = classBind.bind(styles);

export default function SyncDisk() {
  const { userViewData } = useSelector((state) => state.GlobalData);
  const { setUserViewDataRq } = useDispatch().GlobalData;


  async function openSyncDiskConfirm() {
    const { syncDiskConfirmed: isConfirmed } = userViewData;

    if (!isConfirmed) {
      cooperConfirm({
        title: intl.t('安全提示'),
        content: (
          <span style={{ 'word-break': 'keep-all' }}>
            {intl.t('是否允许此功能获取您需要备份的本地文件夹访问权限？')}
          </span>
        ),
        className: 'syncDisk-confirm',
        onOk: async () => {
          const data = { ...userViewData };
          data.syncDiskConfirmed = true;
          if (JSON.stringify(userViewData) === '{}') return;
          setUserViewDataRq(data)
          openSyncDisk();
        },
      });
    } else {
      openSyncDisk();
    }
  }

  return (
    <div className={cx('cooper-sync')}>
      <Tooltip title={intl.t('同步盘')}>
        <img
          src={syncDisk}
          onClick={openSyncDiskConfirm} />
      </Tooltip>
    </div>
  );
}
