import { intl, getLocale } from 'di18n-react';

/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-12 14:38:31
 * @LastEditTime: 2023-09-11 20:37:55
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/IconFamily/CooperTip/index.js
 *
 */
import { CooperTipImg } from '@/assets/icon/index';
import { Popover } from 'antd';
import { Fragment } from 'react';
import './index.less';

function CooperTip(props) {
  const goToBpm = () => {
    window.open('https://bpm.didichuxing.com/process/form/bykey/cooperqxsqlc?tenantId=Cooper')
  }
  const _renderContent = () => {
    return (
      <div className="tip-content">
        <div className="left">
          <img src={CooperTipImg} />
        </div>
        <div className="right">
          {
            intl.t('Cooper作为企业办公场景文档协作平台，禁止上传用户隐私数据，请确保存储数据无违法违规内容。如存储的数据中涉及C3及以上数据，请进入')
          }
          <span
            onClick={goToBpm}
            className='bpm-link'>{intl.t('BPM平台申请空间')}</span>
          {
            intl.t('数据加密。')
          }
        </div>
      </div>
    );
  };
  const onVisibleChange = (visible) => {
    if (visible) {
      window.__OmegaEvent('ep_leftbar_safety_ck');
    }
  }
  return (
    <Fragment>
      <div
        className={`
        tip-btn 
        ${props.type === 'small' ? 'tip-btn-small' : ''}
        ${getLocale() === 'en-US' ? 'tip-btn-long' : ''}
        `}
      >
        <Popover
          content={_renderContent()}
          placement={props.type === 'small' ? 'rightTop' : 'bottomRight'}
          overlayClassName="cooper-tip-popover"
          onVisibleChange={onVisibleChange}
        >
          <img src={CooperTipImg} />
          <span className="cooper-tip-name">{intl.t('数据安全')}</span>
        </Popover>
      </div>
    </Fragment>
  );
}

export default CooperTip;
