import { abortPromise } from '../../util/abortPromise';
import CooperApi from '@/utils/request/api/CooperApi';
// TODO:本地调试时可以用这个
// import MD5_BUFFER_WORKER from '../../worker/md5-buffer';

export const sliceMD5AndBufferEngine = (file) => (start, end) => new Promise((resolve, reject) => {
  const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
  fileReader.onload = function (e) {
    const worker = new Worker('/static/js/md5-buffer.js', { name: 'buffer_worker' });
    // TODO: 失败后为什么要重试最后一个分片 Bug
    worker.onmessage = (response) => {
      if (response.data.action === 'end') {
        worker.terminate();
        resolve({ stream: file.slice(start, end), md5: response.data.md5, size: (end - start) });
      }
    };
    worker.postMessage({ data: e.target.result, action: 'update' });
    worker.postMessage({ data: null, action: 'end' });
  };
  fileReader.onerror = function (err) {
    reject(err);
  };
});

export const sliceAndUpload = (
  fileItem,
  start,
  end,
  seqId,
) => ({ isSliceSeconds, sliceSeconds, sliceUpload }) => (setFilePool, checkHandel, updateCurrSumMd5s) => {
  checkHandel(fileItem);
  let fn = abortPromise(sliceMD5AndBufferEngine(fileItem.get('file'))(start, end));
  setFilePool(fileItem.get('id'))(['fn'], fn);

  return fn.then((buffer) => {
    updateCurrSumMd5s(buffer.md5, fileItem, seqId);
    if (isSliceSeconds) {
      checkHandel(fileItem);
      fn = sliceSeconds({
        fileId: fileItem.get('id'),
        seqId,
        md5: buffer.md5,
        size: buffer.size,
        token: fileItem.get('token'),
      });
      setFilePool(fileItem.get('id'))(['fn'], fn);
      return fn.then((exist) => {
        if (!exist.exist) {
          checkHandel(fileItem);
          fn = sliceUpload({
            fileId: fileItem.get('id'),
            seqId,
            md5: buffer.md5,
            size: buffer.size,
            stream: buffer.stream,
            token: fileItem.get('token'),
          });
          setFilePool(fileItem.get('id'))(['fn'], fn);
          return fn;
        }
        return exist;
      });
    }
    checkHandel(fileItem);
    fn = sliceUpload({
      fileId: fileItem.get('id'),
      seqId,
      md5: buffer.md5,
      size: buffer.size,
      stream: buffer.stream,
    });
    setFilePool(fileItem.get('id'))(['fn'], fn);

    return fn;
  });
};
