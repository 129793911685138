/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-08-09 11:03:04
 * @LastEditTime: 2024-04-18 12:08:58
 * @Description: 存放所有不需要侧边栏以及需要单独定义header的页面
 * @FilePath: /knowledgeforge/src/routes/router-global.js
 *
 */

import loadable from '@loadable/component';
import store from '@/model';

const TeamInvite = loadable(() => import('@/pages/cooper/TeamInvite'));
const CooperSharesFolder = loadable(() => import('@/pages/cooper/Share/CooperShareDownload/index'));
const SendFileDc = loadable(() => import('@/pages/cooper/cooper-sendfile-dc'));
const TenantLoginPage = loadable(() => import('@/pages/cooper/TenantLogin'));
const SpaceInvalid = loadable(() => import('@/pages/cooper/SpaceInvalid'));
const Login = loadable(() => import('@/pages/cooper/Login'));
const DiNOte = loadable(() => import('@/pages/cooper/DiNote'));
const SpaceForbid = loadable(() => import('@/pages/cooper/SpaceForbid'));


let route = [
  {
    path: '/teams/invite/:inviteId',
    name: '团队添加成员',
    exact: true,
    element: <TeamInvite />,
    key: 'teamSpace',
  },
  {
    path: '/team-file/team-invalid',
    name: '空间失效或链接错误',
    exact: true,
    key: 'teamSpace',
    element: <SpaceInvalid />,
  },
  {
    path: '/team-file/team-forbid/:type',
    name: '空间不允许访问',
    exact: true,
    key: 'teamSpace',
    element: <SpaceForbid />,
  },
  {
    path: 'shares/:shareid',
    name: '分享文件夹',
    exact: true,
    key: 'share',
    element: <CooperSharesFolder />,
  },
  {
    path: '/send-file',
    name: '发送文件',
    exact: true,
    key: 'sendFile',
    element: <SendFileDc/>,
  },
  {
    path: '/tenant',
    name: '租户切换',
    key: 'global',
    exact: true,
    element: <TenantLoginPage />,
  },
  {
    path: '/note/*',
    name: '氢笔记',
    key: 'note',
    exact: true,
    element: <DiNOte />,
  },
  {
    path: '/loginPage',
    name: () => '登陆',
    key: 'global',
    exact: true,
    element: <Login />,
  },
];

let globalRoute = []


// eslint-disable-next-line import/prefer-default-export
export const getGlobalRoute = () => {
  const { IsExternalTenant, tenantConfig } = store.getState().GlobalData
  if (IsExternalTenant) {
    globalRoute = route.filter((item) => tenantConfig.find((s) => s.configName === item.key)?.enable || item.key === 'global')
  } else {
    globalRoute = route
  }
  return { globalRoute };
};
