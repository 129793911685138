import CooperHelper from './CooperHelper/index';
import classBind from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import Draggable from 'react-draggable';
import CooperSurvey from './CooperSurvey';
import { useSelector } from 'react-redux';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const TOP = 160;

function GlobalUtils(props) {
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);
  const { surveyInfo } = props;

  const { pathname } = useLocation();
  const [position, setPosition] = useState(TOP);
  const [isDrag, setIsDrag] = useState(false);

  return (
    <>
      {
        surveyInfo?.open && !IsExternalTenant && pathname === '/' && (
          <Draggable
            position={{ x: 0, y: position }}
            axis='y'
            onDrag={() => {
              setIsDrag(true);
            }}
            onStop={(e, ui) => {
              if (ui.y < 0) {
                setPosition(0)
              } else if (ui.y > (document.body.clientHeight - 48)) {
                setPosition(document.body.clientHeight - 48)
              } else {
                setPosition(ui.y);
              }
              setTimeout(() => {
                setIsDrag(false);
              }, 400);
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div
              className={cx('global-survey-wrap')}
              // style={{
              //   top: position,
              // }}
            >
              <CooperSurvey
                isDrag={isDrag}
                surveyInfo={surveyInfo}
              />
            </div>
          </Draggable>
        )
      }
    </>
  )
}

export default GlobalUtils
