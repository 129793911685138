import commonCss from '../common/commonCss.js';
import replaceIntl from '../utils/wrapWithIntl.js';
import body from './components/body.js';
import GetHtml from '@/utils/DOMPurify.js'
// import temp from './temp.js'

// const body = require('./components/body.js')

const bodyWithIntl = replaceIntl(body)

const renderStr = /* html */`
<style>
  ${commonCss}
  .home-skeleton-wrap {
    position: relative;
    width: 100%;
  }
</style>
<div id="right-home" class="right">
${bodyWithIntl}
</div>
`
export default GetHtml(renderStr);
