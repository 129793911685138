import CryptoJS from 'crypto-js';
import encHex from 'crypto-js/enc-hex';
import { fromJS } from 'immutable';
import { abortPromise } from './abortPromise';
import { uploadSize } from './constants';

// TODO:本地调试时可以用这个
// import MD5_BUFFER_WORKER from '../worker/md5-buffer';

import CooperApi from '@/utils/request/api/CooperApi';
import { matchIntegerCriticalValue } from './index';

export const fileSlice = (file) => (start, end) => new Promise((resolve, reject) => {
  const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
  fileReader.onload = function (e) {
    resolve(e.target.result);
  };
  fileReader.onerror = function (err) {
    reject(err);
  };
});

export const limitFileSlice = (file, seqId, chunks) => (worker) => {
  const start = seqId * uploadSize.sliceSize;
  const end = (start + uploadSize.sliceSize) >= file.size ? file.size : start + uploadSize.sliceSize;
  return fileSlice(file)(start, end).then((arrBuffer) => {
    seqId += 1;
    worker.postMessage({ data: arrBuffer, other: seqId, action: 'update' });
    if (seqId < chunks) {
      return limitFileSlice(file, seqId, chunks)(worker);
    }
    return 1;
  });
};

// export const md5Engine = (file, cb) => new Promise((resolve) => {
//   const chunks = Math.ceil(file.size / uploadSize.sliceSize);
//   const worker = new Worker(CooperApi.MD5_BUFFER_WORKER, { name: 'normal_worker_md5Engine'});
//   worker.onmessage = response => {
//     if (response.data.action === 'end') {
//       worker.terminate();
//       resolve(response.data.md5);
//     }
//     if (response.data.action === 'update') {
//       const preProgress = matchIntegerCriticalValue(10, 0)(((response.data.other - 1) / chunks) * 10);
//       const progress = matchIntegerCriticalValue(10, 0)((response.data.other / chunks) * 10);
//       if (progress !== preProgress); cb(progress, worker);
//     }
//   };
//   limitFileSlice(file, 0, chunks)(worker, cb).then(() => {
//     worker.postMessage({data: null, action: 'end'});
//   });
// });

// TODO:放到worker中或者window.requestIdleCallback();
// export const md5Engine = (file, cb) => {
//   function hashFileInternal(file, alog) {
//     const chunkSize = uploadSize.sliceSize;
//     let promise = Promise.resolve();
//     // 保证update的顺序是文件正确的顺序
//     for (let index = 0; index < file.size; index += chunkSize) {
//       promise = promise.then(() => hashBlob(file.slice(index, index + chunkSize)));
//     }

//     function hashBlob(blob) { // 此处流式读取文件，增量计算md5，解决了大量占用内存的问题
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onload = ({ target }) => {
//           const wordArray = CryptoJS.lib.WordArray.create(target.result);
//           // 增量更新计算结果
//           alog.update(wordArray);
//           resolve();
//         };
//         reader.readAsArrayBuffer(blob);
//       });
//     }

//     return promise.then(() => {
//       cb(1);
//       return encHex.stringify(alog.finalize());
//     });
//   }

//   return hashFileInternal(file, CryptoJS.algo.MD5.create());
// };

export const md5Engine = (file, cb) => {
  return Promise.resolve('');
};


export const getFileSecondsTicket = (fileItem, cb) => {
  const file = fileItem.get('file');
  return abortPromise(md5Engine(file, cb)).then((md5) => {
    return fromJS({ fileId: fileItem.get('id'), md5, size: file.size, token: fileItem.get('token') });
  });
};
