import commonCss from '../common/commonCss.js';
import replaceIntl from '../utils/wrapWithIntl.js';
import body from './components/body.js';

const bodyWithIntl = replaceIntl(body)

const renderStr = /* html */`
<style>
  ${commonCss}
  #right-favorite .favorite-wrap  {
    height: 100%
  }

  #right-favorite .page-title-wrap  {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-bottom  : 18px;
    padding        : 0 32px
  }

  #right-favorite .page-title-wrap .title-name  {
    font-size  : 16px;
    line-height: 24px;
    font-weight: 500;
    color      : #222a35;
    display    : inline-flex;
    align-items: center;
    line-height: 34px
  }
  #right-favorite .ant-table-column-sorter  {
    margin-left: 4px;
    color      : #bfbfbf;
    font-size  : 0;
    transition : color .3s
  }

  #right-favorite .ant-table-column-sorter-down ,
  #right-favorite .ant-table-column-sorter-up  {
    font-size: 11px
  }

  #right-favorite .favorite-skeleton-box  {
    height: 100%
  }

  #right-favorite .favorite-skeleton-box .favorite-skeleton-header  {
    display        : flex;
    justify-content: left;
    align-items    : center;
    padding        : 0 32px
  }

  #right-favorite .favorite-skeleton-box .favorite-skeleton-header .title-name  {
    font-size  : 16px;
    line-height: 24px;
    font-weight: 500;
    color      : #222a35;
    display    : inline-flex;
    align-items: center;
    line-height: 34px
  }

  #right-favorite .favorite-skeleton-box .favorite-skeleton-body  {
    padding: 0px 32px 0
  }

  #right-favorite .global-search-wrap .search-tag  {
    margin-left  : 8px;
    padding      : 0 4px 0 8px;
    border-radius: 3px;
    background   : #ebeef1;
    display      : flex;
    align-items  : center;
    color        : rgba(0, 0, 0, .7)
  }

  #right-favorite .global-search-wrap .search-tag>span>i  {
    color    : #333;
    font-size: 14px;
    cursor   : pointer
  }
</style>
<div id="right-favorite" class="right">
${bodyWithIntl}
</div>
`
export default renderStr;
