import NewCooperGuide from './NewCooperGuide';
import NewSpaceGuide from './NewSpaceGuide';
import NewPaintGuide from './NewPaintGuide';
import { useSelector } from 'react-redux';


function NoviceGuide() {
  const { renderNewSpaceGuide } = useSelector((state) => state.GlobalData);

  return <>
    {
      renderNewSpaceGuide && <NewSpaceGuide />
    }
    <NewCooperGuide />
    <NewPaintGuide />
  </>;
}

export default NoviceGuide;
