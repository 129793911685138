module.exports = `
<style>
.banner {
  width: 100%;
  height: 160px;
  padding: 0 32px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.banner .banner-item {
  border-radius: 8px;
}

.banner .banner_left {
  position: relative;
  background-color: #F6F7F7;
  flex: 4 4 300px;
}

.banner .banner_left .banner_left-title {
  padding-top: 14px;
}

.banner .banner_left .banner-skeleton-title {
  height: 20px !important;
}

.banner .banner_left .banner-skeleton-title-1 {
  width: 480px;
  height: 14px !important;
}

.banner .banner_left .banner-skeleton-title-2 {
  width: 360px;
  height: 14px !important;
}

.banner .banner_left .data-item {
  margin-top: 12px;
}

.banner .banner_right {
  flex:1 0 200px;
  background-color: #F6F7F7;
  min-width: 200px;
}

.banner .banner_right .banner-skeleton-title-short {
  width: 100px;
  height: 20px !important;
}

.banner .banner_right .data-item {
  margin-top: 12px;
}

.banner .ant-card {
  padding: 26px 24px;
  border-radius: 8px;
  background-color: #F6F7F7;
}

.banner .ant-card-body {
  padding: 0;
}

.banner .ant-card-head-wrapper {
  display: flex;
  align-items: center;
}

.banner .ant-card-head {
  border-radius: 8px 8px 0 0;
  border: 0;
  padding: 0;
  min-height: 0;
  height: 22px;
  line-height: 22px;
}

.banner .ant-card-head-title {
  padding: 0;
  color: #297E19;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
}

.banner .ant-card-extra {
  padding: 0;
  color: #297E19;
  font-size: 12px;
  margin-left: auto;
  font-weight: normal;
}

.banner .dk-iconfont {
  margin-left: 4px;
  font-size: 12px;
  line-height: 22.5px;
}

.banner .ant-card-bordered {
  border: 0;
}

.banner .rotating-data-container {
  margin-top: 20px;
}

.banner .data-item {
  margin-top: 20px;
}

.faq-item {
  background-color: #FFF;
  margin-top: 10px;
  padding: 9px 12px;
}

.banner .banner-skeleton-title {
  position: absolute;
  top: 17px;
  left: 62px;
  width: 200px;
  height: 14px !important;
}

.banner .banner-skeleton-title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  font-weight: 400;
  color: #222a35;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  height: 24px;
  display: block;
  margin-bottom: 0;
  padding: 0 0 12px;
  box-sizing: content-box;
}

.banner .banner-skeleton-button * {
  min-width: auto !important;
  width: 100% !important;
  height: 100% !important
}
</style>
<div class="banner"><div class="banner_left banner_example banner-item"><div class="banner_left-title"><div class="data-item"><div class="banner-skeleton-title banner-skeleton-button"><div class="ant-skeleton ant-skeleton-element ant-skeleton-active"><span class="ant-skeleton-button ant-skeleton-button-round"></span></div></div></div></div><div class="banner_left-text"><div class="data-item"><div class="banner-skeleton-title banner-skeleton-title-1 banner-skeleton-button"><div class="ant-skeleton ant-skeleton-element ant-skeleton-active"><span class="ant-skeleton-button ant-skeleton-button-round"></span></div></div></div><div class="data-item"><div class="banner-skeleton-title banner-skeleton-title-2 banner-skeleton-button"><div class="ant-skeleton ant-skeleton-element ant-skeleton-active"><span class="ant-skeleton-button ant-skeleton-button-round"></span></div></div></div></div></div><div class="banner_right banner-item"><div class="ant-card ant-card-bordered ant-card-middle" style="width: 100%; height: 160px;"><div class="ant-card-head"><div class="ant-card-head-wrapper"><div class="banner-skeleton-title banner-skeleton-title-short banner-skeleton-button"><div class="ant-skeleton ant-skeleton-element ant-skeleton-active"><span class="ant-skeleton-button ant-skeleton-button-round"></span></div></div></div></div><div class="ant-card-body"><div class="rotating-data-container"><div class="data-item"><div class="banner-skeleton-title banner-skeleton-button"><div class="ant-skeleton ant-skeleton-element ant-skeleton-active"><span class="ant-skeleton-button ant-skeleton-button-round"></span></div></div></div><div class="data-item"><div class="banner-skeleton-title banner-skeleton-button"><div class="ant-skeleton ant-skeleton-element ant-skeleton-active"><span class="ant-skeleton-button ant-skeleton-button-round"></span></div></div></div></div></div></div></div></div>
`
