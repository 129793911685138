/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-05 19:58:59
 * @LastEditTime: 2023-07-27 15:00:59
 * @Description: header配置
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/Header/index.js
 *
 */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classBind from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import CooperSearch from '@/components/CooperSearch/index';
import NewCreate from '@/components/LayoutCooper/Header/NewCreate';
import NewUpload from '@/components/LayoutCooper/Header/NewUpload';
import SyncDisk from '@/components/SyncDisk/index';
import NewCreateTeam from '@/components/LayoutCooper/Header/NewCreateTeam';
import { isShowSD } from '@/components/SyncDisk/dc-helper';
import { isDC } from '@/utils/index';
import { isDiDiTenant } from '@/utils/entryEnhance';
import styles from './style.module.less';
import CustomerService from '@/baseComponents/CustomerService';

const cx = classBind.bind(styles);

function Header() {
  const [isShowSDIcon, setIsShowSDIcon] = useState(false)
  const { pathname } = useLocation()
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);

  useEffect(() => {
    (async () => {
      const value = await isShowSD();
      setIsShowSDIcon(value && window.navigator.userAgent.indexOf('D-Chat/4.') === -1);
    })()
  }, [])

  const isTeamDetail = pathname.includes('/team-file');
  const isPersonDetail = pathname.includes('/disk') || pathname.includes('/files/');
  const isKnowledgeDetail = pathname.includes('/knowledge/');
  const isShareList = pathname.includes('/tome') || pathname.includes('/fromme');
  const isFavoriteList = pathname.includes('/favorite');
  const isTraskList = pathname.includes('/trash');
  const isLibraryList = pathname.includes('/library');

  const needHeader = !(isTeamDetail || isPersonDetail || isKnowledgeDetail);
  const needTopAction = pathname === '/'
  const needCreateSpace = pathname.includes('/team-folder');

  return (
    needHeader && <div className={cx('header-warp', { 'dc-header-warp': isDC() })}>
      <div className={cx('header-top')} key={pathname}>
        <CooperSearch/>
        {needTopAction && <div className={cx('title-opt')}>
          {isShowSDIcon && <SyncDisk/>}
          {!IsExternalTenant && <NewUpload />}
          {!IsExternalTenant && <NewCreate />}
        </div>}
        {
          isDiDiTenant() && needCreateSpace && (
            <div className={cx('title-opt', 'title-opt-with-create-space')}>
              <NewCreateTeam />
            </div>
          )
        }
        <CustomerService hideSpliteLine={isShareList || isFavoriteList || isTraskList || isLibraryList}/>
      </div>
    </div>
  );
}

export default Header;
