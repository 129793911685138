import React from 'react';
import OperateFileModal from '@/components/OperateFileModal';
import { UPLOAD } from '@/constants/cooper';
import store from '@/model';
import { Provider } from 'react-redux';

const UploadTo = ({
  files,
  onUpload = () => { },
  onClose = () => { },
}) => {
  return (
    <Provider
      store={store}
    >
      <div className='upload-to'>
        <OperateFileModal
          type={UPLOAD}
          files={files}
          autoLocate={false}
          doneCallback={onUpload}
          cancelCallback={onClose}
          onClose={onClose}
          isFromPerson={false}
        />
      </div>
    </Provider>

  );
}
export default UploadTo;
