import classBind from 'classnames/bind';
import styles from './style.module.less';
import { SmallLogo, LargeLogo } from '@/assets/icon';
import { Link } from 'react-router-dom';

const cx = classBind.bind(styles);

function Logo({ isLarge = true }) {
  return (
    <Link
      className={cx('logo')}
      to='/'
  >
      <img
        className={cx('logo-img', `${isLarge ? 'logo-large' : 'logo-small'}`)}
        src={
          isLarge
            ? LargeLogo
            : SmallLogo
      }
        alt=''
  />
    </Link>
  );
}

export default Logo;
