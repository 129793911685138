import { intl } from 'di18n-react';
import React from 'react';
import { formatSize } from '@/utils/upload';
import { uploadContainerStatus } from '@/components/Upload/UploadEngine';
import Operation from './operation';
import { getIconByName } from './util';
import './index.less';

class Body extends React.Component {
  _renderList = () => {
    const listItem = [];
    const { data = {}, uploadCase } = this.props;
    Object.values(data.tasks || {}).map((task) => {
      return task.taskItems.map((taskItem) => {
        listItem.push(<div
          className='item-container'
          key={taskItem.id}>
          <div className='title-container'>
            <img
              alt='ICON'
              src={getIconByName(taskItem.file.name)} />
            <div className='text-container'>
              <div className='name'>{taskItem.file.name}</div>
              <div className='hint-container'>
                <div className='size'>{formatSize(taskItem.file.size)}</div>
              </div>
            </div>
          </div>
          <Operation
            fileItem={taskItem}
            pause={uploadCase.pause}
            cancel={uploadCase.cancel}
            goOn={uploadCase.goOn}
            retry={uploadCase.retry}
            jumpTo={this.props.jumpTo}
          />
        </div>);
        return taskItem;
      });
    });
    return listItem;
  };

  render() {
    const { listSwitch, data = {}} = this.props;
    return (
      <div className={`body ${listSwitch ? 'body-open' : 'body-close'}`}>
        {data
        && data.status
        && data.status.status === uploadContainerStatus.checking.status ? (
          <div className='loading-c'>
            <img
              className='loading-c-img'
              src={require('./icon/loading.svg')}
            />
            <div className='loading-c-text'>
              {intl.t('正在创建上传任务...')}
            </div>
          </div>
          ) : (
            <div className='list-items'>{this._renderList()}</div>
          )}
      </div>
    );
  }
}

export default Body;
