const FileTableSkeleton = require('../../common/FileTableSkeleton');


const ret = /* html */`
  <div class="body-wrap">
    <div class="favorite-skeleton-box">
      <div class="favorite-skeleton-header" style="margin-bottom: 18px"><!-- NOTE: 覆盖样式 -->
        <div class="title-name">我的收藏</div>
      </div>
      <div class="favorite-skeleton-body">
        ${FileTableSkeleton}
      </div>
    </div>
  </div>
  `

module.exports = ret
