import commonCss from '../common/commonCss';
import replaceIntl from '../utils/wrapWithIntl';
import body from './components/body';

const bodyWithIntl = replaceIntl(body)

const renderStr = /* html */`
<style>
  ${commonCss}
  #right-share-fromme .ant-tabs-bottom ,
  #right-share-fromme .ant-tabs-top  {
    flex-direction: column
  }

  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav  {
    margin: 0 0 16px
  }

  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav:before ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav:before ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav:before ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav:before  {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid rgba(34, 42, 53, .08);
    content: ""
  }

  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar  {
    height: 2px
  }

  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar-animated ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar-animated ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar-animated ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar-animated  {
    transition: width .3s, left .3s, right .3s
  }

  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:before ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:before ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:before ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:before  {
    top: 0;
    bottom: 0;
    width: 30px
  }

  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:before ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:before ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:before ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:before  {
    left: 0;
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, .08)
  }

  #right-share-fromme .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:after  {
    right: 0;
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, .08)
  }

  #right-share-fromme .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar ,
  #right-share-fromme .ant-tabs-top>.ant-tabs-nav:before ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar ,
  #right-share-fromme .ant-tabs-top>div>.ant-tabs-nav:before  {
    bottom: 0
  }

  #right-share-fromme .ant-tabs  {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #222a35;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    display: flex
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav  {
    position: relative;
    display: flex;
    flex: none;
    align-items: center
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap  {
    position: relative;
    display: inline-block;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transform: translate(0);
    transform: translate(0)
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:before ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap:after ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap:before  {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s;
    content: "";
    pointer-events: none
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list  {
    position: relative;
    display: flex;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations  {
    display: flex;
    align-self: stretch
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden  {
    position: absolute;
    visibility: hidden;
    pointer-events: none
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more  {
    position: relative;
    padding: 8px 16px;
    background: transparent;
    border: 0
  }

  #right-share-fromme .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more:after ,
  #right-share-fromme .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more:after  {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    content: ""
  }

  #right-share-fromme .ant-tabs-ink-bar  {
    position: absolute;
    background: #047ffe;
    pointer-events: none
  }

  #right-share-fromme .ant-tabs-tab  {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 16px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer
  }

  #right-share-fromme .ant-tabs-tab-btn ,
  #right-share-fromme .ant-tabs-tab-remove  {
    outline: none;
    transition: all .3s
  }

  #right-share-fromme .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
    color: #222a35;
    text-shadow: 0 0 .25px currentcolor
  }

  #right-share-fromme .ant-tabs-tab+.ant-tabs-tab  {
    margin: 0 10px 0 32px
  }

  #right-share-fromme .ant-tabs-content  {
    display: flex;
    width: 100%
  }

  #right-share-fromme .ant-tabs-content-holder  {
    flex: auto;
    min-width: 0;
    min-height: 0
  }

  #right-share-fromme .ant-tabs-tabpane  {
    flex: none;
    width: 100%;
    outline: none
  }


  #right-share-fromme .ant-tabs {
    color: rgba(34, 42, 53, .7);
    position: relative
  }

  #right-share-fromme .ant-tabs .ant-tabs-nav {
    background: #fff
  }

  #right-share-fromme .ant-tabs .ant-tabs-nav:before {
    border-bottom: none
  }

  #right-share-fromme .ant-tabs .ant-tabs-tab {
    font-size: 16px;
    line-height: 38px;
    padding: 0
  }

  #right-share-fromme .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500
  }

  #right-share-fromme .ant-tabs .ant-tabs-ink-bar {
    background-color: #047ffe;
    height: 2px !important;
    border-radius: 8px
  }


  #right-share-fromme .tab-top-title  {
    flex: 1
  }

  #right-share-fromme .tab-top-title .ant-tabs-content-top  {
    height: 100%
  }

  #right-share-fromme .tab-top-title .ant-tabs-nav  {
    margin-bottom: 15px
  }

  #right-share-fromme .tab-top-title .ant-tabs-ink-bar  {
    display: none !important
  }

  #right-share-fromme .tab-top-title .ant-tabs-tab-active  {
    position: relative
  }

  #right-share-fromme .tab-top-title .ant-tabs-tab-active:before  {
    content: "";
    width: 24px;
    height: 2px;
    background-color: #047ffe;
    left: calc(50% - 12px);
    bottom: 0;
    z-index: 100;
    position: absolute
  }

  #right-share-fromme .tab-top-large .ant-tabs-tab  {
    font-size: 16px !important;
    line-height: 24px !important;
    padding-top: 4px !important;
    padding-bottom: 6px !important
  }


  #right-share-fromme .share-skeleton-box  {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    height: 100%
  }

  #right-share-fromme .share-skeleton-box .share-skeleton-title-wrap  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    padding: 0 32px
  }

  #right-share-fromme .share-skeleton-box .share-skeleton-body-wrap  {
    padding: 0 32px;
    height: 100%
  }

  #right-share-fromme .share-skeleton-box .share-skeleton-body-wrap .ant-tabs-nav  {
    margin: 0 0 16px !important
  }


  #right-share-fromme .share-skeleton-box .skeleton-fromme .file-skeleton-box  {
    height: 100%;
    overflow-x: hidden;
    margin-top: -12px;
    margin-bottom: 12px
  }

  #right-share-fromme .share-skeleton-box .skeleton-fromme .file-body-skeleton-box  {

    padding-top: 8px !important
  }

  #right-share-fromme .dk-icon-fenxiang1:before  {
    content: "\e752"
  }

  #right-share-fromme .dk-icon-zhanneixin:before  {
    content: "\e6f1"
  }

  #right-share-fromme .dk-icon-tuanduikongjian4px:before  {
    content: "\e6f5"
  }
</style>
<div id="right-share-fromme" class="right">
  ${bodyWithIntl}
</div>
`

export default renderStr;
