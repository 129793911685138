/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-08 11:04:30
 * @LastEditTime: 2023-06-09 10:34:26
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/Aside/index.js
 *
 */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COOPER_ASIDE_TYPE } from '@/constants/index';
import { setLocalData } from '@/utils/localStorage';
import AsideLarge from './AsideLarge';
import AsideSmall from './AsideSmall';

function Aside() {
  const { asideType, userViewData } = useSelector((state) => state.GlobalData);
  const { setAsideType } = useDispatch().GlobalData;

  useEffect(() => {
    const { Aside_Type_Cooper: asideCooper } = userViewData;
    if (asideCooper) {
      setAsideType(asideCooper);
      setLocalData('asideType', asideCooper);
    }
  }, [userViewData?.Aside_Type_Cooper])

  return (
    <>
      {/* <AsideSmall overlayClassName={asideType !== COOPER_ASIDE_TYPE.SMALL ? 'no-width' : ''} />
      <AsideLarge overlayClassName={asideType === COOPER_ASIDE_TYPE.SMALL ? 'no-width' : ''} /> */}
      {asideType === COOPER_ASIDE_TYPE.SMALL && <AsideSmall />}
      {asideType === COOPER_ASIDE_TYPE.LARGE && <AsideLarge />}
    </>
  );
}

export default Aside;
