/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-21 14:21:59
 * @LastEditTime: 2023-09-13 11:10:17
 * @Description: 创建团队空间
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/Header/NewCreateTeam/index.js
 *
 */
import { intl } from 'di18n-react';
import { Button } from 'antd';
import CreateTeam from '@/components/CooperOperation/CreateTeam';

function NewCreateTeam() {
  const createTeamClick = () => {
    window.__OmegaEvent('ep_team_home_create_ck');
    CreateTeam();
  }

  return (
    <Button
      onClick={createTeamClick}
      type="primary"
      icon={<i className="dk-iconfont dk-icon-a-" />}
    >
      {intl.t('新建团队空间')}
    </Button>
  );
}

export default NewCreateTeam;
