import React, { Suspense } from 'react';
import GetHtml from '@/utils/DOMPurify';
import SkeletonPage from '@/components/SkeletonPage';
import FavoriteSkeleton from '@/components/SkeletonPage/Favorite/favoriteForImport';
import HomeSkeleton from '@/components/SkeletonPage/Home/homeForImport';
import PersonSkeleton from '@/components/SkeletonPage/Person/personForImport';
import ShareFromMeSkeleton from '@/components/SkeletonPage/ShareFromMe/shareFromMeForImport';
import ShareToMeSkeleton from '@/components/SkeletonPage/ShareToMe/shareToMeForImport';
import TeamSkeleton from '@/components/SkeletonPage/Team/teamForImport';
import TeamDetailSkeleton from '@/components/SkeletonPage/TeamDetail/teamDetailForImport';
import TrashSkeleton from '@/components/SkeletonPage/Trash/trashForImport';
import { intl } from 'di18n-react';
import { getDkRoute } from './route-dk';
import store from '@/model';
// import loadable from '@loadable/component';

const Home = React.lazy(() => import('@/pages/cooper/Home'));
const Space = React.lazy(() => import('@/pages/cooper/Space'));
const Favorite = React.lazy(() => import('@/pages/cooper/Favorite'));
const Share = React.lazy(() => import('@/pages/cooper/Share'));
const Trash = React.lazy(() => import('@/pages/cooper/Trash'));
const MyKnowledge = React.lazy(() => import('@/pages/cooper/KnowledgeList'));
const Library = React.lazy(() => import('@/components/LayoutCooper/IconFamily/CooperLibrary/Library/index.js'));
const SpaceDetail = React.lazy(() => import('@/pages/cooper/SpaceDetail'));
const VideoPreview = React.lazy(() => import('@/components/CooperVideoPreview/index'));
const PersonDetail = React.lazy(() => import('@/pages/cooper/SpaceDetail/PersonDetail'));
const PreviewPage = React.lazy(() => import('@/pages/cooper/Preview'));
// const LayoutDK = loadable(() => import('@/components/Layout'))
// const SpaceInvite = loadable(() => import('@/pages/cooper/SpaceInvite'))
// 侧边栏导航路由
let aside = [
  {
    path: '/',
    name: () => intl.t('首页'),
    key: 'home',
    exact: true,
    icon: 'dk-icon-zhuye4',
    iconSelected: 'dk-icon-zhuye-mian',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(HomeSkeleton) }} />
        }
      >
        <Home />
      </Suspense>
    ),
  },
  {
    path: '/disk',
    name: () => intl.t('个人空间'),
    exact: true,
    icon: 'dk-icon-gerenkongjian',
    iconSelected: 'dk-icon-rongqi',
    key: 'personSpace',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(PersonSkeleton) }} />
        }
      >
        <PersonDetail />
      </Suspense>
    ),
  },
  {
    path: '/team-folder',
    name: () => intl.t('团队空间'),
    exact: true,
    icon: 'dk-icon-tuanduikongjian4px',
    iconSelected: 'dk-icon-tuanduikongjian',
    key: 'teamSpace',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(TeamSkeleton) }} />
        }
      >
        <Space />
      </Suspense>
    ),
  },
  {
    path: '/dk', // 实际没有用
    name: () => intl.t('知识库'),
    exact: true,
    icon: 'dk-icon-zhishikuxian',
    iconSelected: 'dk-icon-zhishikumian1',
    key: 'knowledge',
    element: (
      <Suspense fallback={<SkeletonPage />}>
        <MyKnowledge />
      </Suspense>
    ),
  },
  // {
  //   path: '/portalList',
  //   name: () => intl.t('知识门户'),
  //   exact: true,
  //   icon: 'dk-icon-menhuxian',
  //   iconSelected: 'dk-icon-menhumian',
  //   element: <Suspense fallback={<SkeletonPage/>}><PortalList /></Suspense>,
  // },
  {
    path: '/tome',
    name: () => intl.t('分享'),
    exact: true,
    icon: 'dk-icon-fenxiang4px',
    iconSelected: 'dk-icon-fenxiang1',
    key: 'share',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(ShareToMeSkeleton) }} />
        }
      >
        <Share />
      </Suspense>
    ),
  },
  {
    path: '/favorite',
    name: () => intl.t('收藏'),
    exact: true,
    icon: 'dk-icon-shoucang4px',
    iconSelected: 'dk-icon-yishoucang1',
    key: 'star',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(FavoriteSkeleton) }} />
        }
      >
        <Favorite />
      </Suspense>
    ),
  },
  {
    path: '/trash',
    name: () => intl.t('回收站'),
    exact: true,
    icon: 'dk-icon-huishouzhan4px',
    iconSelected: 'dk-icon-huishouzhan1',
    key: 'trash',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(TrashSkeleton) }} />
        }
      >
        <Trash />
      </Suspense>
    ),
  },
];
// 渲染路由
let content = [
  {
    path: '/fromme',
    name: () => intl.t('分享'),
    exact: true,
    icon: 'dk-icon-geren',
    iconSelected: 'dk-icon-geren',
    key: 'share',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(ShareFromMeSkeleton) }} />
        }
      >
        <Share />
      </Suspense>
    ),
  },
  {
    path: '/tome/:shareId/:foldId',
    name: () => intl.t('分享'),
    exact: true,
    icon: 'dk-icon-geren',
    iconSelected: 'dk-icon-geren',
    key: 'share',
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(ShareToMeSkeleton) }} />
        }
      >
        <Share />
      </Suspense>
    ),
  },
  {
    path: '/library',
    name: () => intl.t('实验室'),
    exact: true,
    key: 'library',
    element: <Suspense fallback={<SkeletonPage />}><Library /></Suspense>,
  },
  {
    path: '/team-file/:teamId',
    name: () => intl.t('团队空间文件列表'),
    exact: true,
    key: 'teamSpace',
    element: (
      <Suspense
        fallback={
          // <SkeletonPage />
          <div dangerouslySetInnerHTML={{ __html: GetHtml(TeamDetailSkeleton) }} />
        }
      >
        <SpaceDetail />
      </Suspense>
    ),
    children: (() => {
      return getDkRoute().dkRouteInTeam.map((item) => {
        return {
          ...item,
          path: `/team-file/:teamId/${item.path}`,
        }
      })
    })(),
  },
  {
    path: '/team-file/:teamId/home',
    name: () => intl.t('团队空间首页'),
    key: 'teamSpace',
    exact: true,
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(TeamDetailSkeleton) }} />
        }
      >
        <SpaceDetail />
      </Suspense>
    ),
  },

  {
    path: '/team-file/:teamId/:foldId',
    name: () => intl.t('团队空间文件详情'),
    key: 'teamSpace',
    exact: true,
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(TeamDetailSkeleton) }} />
        }
      >
        <SpaceDetail />
      </Suspense>
    ),
  },

  {
    path: '/files/:foldId',
    name: () => intl.t('文件详情'),
    key: 'personSpace',
    exact: true,
    element: (
      <Suspense
        fallback={
          <div dangerouslySetInnerHTML={{ __html: GetHtml(PersonSkeleton) }} />
        }
      >
        <PersonDetail />
      </Suspense>
    ),
  },
  {
    path: '/team-file/:teamId/trash',
    name: () => intl.t('回收站'),
    exact: true,
    key: 'trash',
    element: <Suspense fallback={<SkeletonPage />}><SpaceDetail /></Suspense>,
  },
  {
    path: '/team-file/:teamId/member',
    name: () => intl.t('成员'),
    exact: true,
    key: 'teamSpace',
    element: <Suspense fallback={<SkeletonPage />}><SpaceDetail /></Suspense>,
  },
  {
    path: '/team-file/:teamId/dynamic',
    name: () => intl.t('动态'),
    exact: true,
    key: 'teamSpace',
    element: <Suspense fallback={<SkeletonPage />}><SpaceDetail /></Suspense>,
  },
  {
    path: '/team-file/:teamId/setup',
    name: () => intl.t('设置'),
    exact: true,
    key: 'teamSpace',
    element: <Suspense fallback={<SkeletonPage />}><SpaceDetail /></Suspense>,
  },
  // {
  //   path: '/team-file/:teamId/knowledge/:knowledgeId',
  //   name: () => intl.t('知识库'),
  //   exact: true,
  //   element: <Suspense fallback={<SkeletonPage />}><SpaceDetail /></Suspense>,
  // },
  // {
  //   path: '/trash/:foldId',
  //   name: () => intl.t('回收站'),
  //   exact: true,
  //   element: <Suspense fallback={<SkeletonPage />}><SpaceDetail /></Suspense>,
  // },
  {
    path: '/videopreview/:type/:id/:shareId',
    name: () => intl.t('视频预览'),
    exact: true,
    key: 'preview',
    element: <Suspense fallback={<SkeletonPage />}><VideoPreview /></Suspense>,
  },
  {
    path: '/preview',
    name: () => intl.t('预览'),
    exact: true,
    key: 'preview',
    element: <Suspense fallback={<SkeletonPage />}><PreviewPage /></Suspense>,
  },
];

let asideRoute = []

let route = []

// 对于Cooper路有需要针对配置文件过滤处理
// eslint-disable-next-line import/prefer-default-export
export const getCooperRoute = () => {
  const { IsExternalTenant, tenantConfig } = store.getState().GlobalData
  if (IsExternalTenant) {
    asideRoute = aside.filter((item) => tenantConfig.find((s) => s.configName === item.key)?.enable)
    route = content.filter((item) => tenantConfig.find((s) => s.configName === item.key)?.enable).concat(asideRoute)
  } else {
    asideRoute = aside
    route = content.concat(asideRoute)
  }
  return { asideRoute, route };
}
