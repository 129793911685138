import { intl } from 'di18n-react';

// 上传容器状态
export const uploadContainerStatus = {
  checking: {
    status: 'checking',
    name: ('校验中'),
  },

  addTo: {
    status: 'addTo',
    name: ('追加上传'),
  },

  uploading: {
    status: 'uploading',
    name: ('正在上传'),
  },

  success: {
    status: 'success',
    name: ('全部上传成功'),
  },

  failed: {
    status: 'failed',
    name: (':num个文件上传失败'),
  },

  paused: {
    status: 'paused',
    name: (':num个文件暂停'),
  },
};

// 上传状态
export const uploadStatus = {
  queueing: {
    status: 'queueing',
    name: ('排队中'),
  },

  checking: {
    status: 'checking',
    name: ('校验中'),
  },

  uploading: {
    status: 'uploading',
    name: ('上传中'),
  },

  success: {
    status: 'success',
    name: ('上传成功'),
  },

  failed: {
    status: 'failed',
    name: ('上传失败'),
  },

  paused: {
    status: 'paused',
    name: ('暂停'),
  },

  cancelled: {
    status: 'cancelled',
    name: ('取消'),
  },
};

export const uploadSize = {
  sliceSize: 1024 * 1024 * 20,
  sliceBoundary: 1024 * 1024 * 50,
  numberOfThreads: 5,
};

export const createTaskConfig = {
  patchCount: 1000,
};
