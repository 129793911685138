import GuideModal from '../GuideModal';
import { intl, getLocale } from 'di18n-react';
import step1 from './gif/1.png';
import step2 from './gif/2.png';
import step3 from './gif/3.png';
import step1US from './gif/1-us.png';
import step2US from './gif/2-us.png';
import step3US from './gif/3-us.png';

function NewSpaceGuide() {
  const isUS = getLocale() === 'en-US';

  const data = [
    {
      title: '空间首页全新上线',
      img: isUS ? step1US : step1,
      summary: '推出团队首页，让重要信息脱颖而出：我们是谁、目标是什么、团队运营方式、正在进行的工作…争做团队仪表盘！',
    },
    {
      title: '关联知识库展示升级',
      img: isUS ? step2US : step2,
      summary: '同一空间下可直接展示文件及知识库内容，降低两个平台跳转的割裂感，提高内容的快速触达效率，提升使用体验！',
    },
    {
      title: '空间新增快捷访问区域',
      img: isUS ? step3US : step3,
      summary: '支持添加/管理标签页，聚合不同类型知识：知识库（已支持）、文档/表格（远期支持）等，自定义变化标签页排序和名称.',
    },
  ];
  return <GuideModal
    config={data}
    finishText={intl.t('开启全新体验')}
    userViewKey= {'GUIDE_DIALOG_SPACE'}
  />;
}

export default NewSpaceGuide;
