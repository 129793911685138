import SearchContent from '@/components/SearchContent'
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);
function CooperSearch() {
  return (
    <div className={cx('header-top-left')}>
      <SearchContent />
    </div>
  )
}

export default CooperSearch
