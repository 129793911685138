import { compose } from 'ramda';

export const obj = {
  handelError() {},
};

export const handelError = function (error) {
  obj.handelError(error);
};

const skipNil = (criticalValue) => (func) => ((criticalValue || criticalValue === 0) ? func : (value) => value);

const matchCeiling = (ceiling) => (value) => (value > ceiling ? ceiling : value);

const matchFloor = (floor) => (value) => (value < floor ? floor : value);

export const matchIntegerCriticalValue = (ceiling, floor) => (value) => {
  const matchCeilingOrNil = compose(skipNil(ceiling), matchCeiling);
  const matchFloorOrNil = compose(skipNil(floor), matchFloor);
  const matchCellingAndFloor = compose(matchFloorOrNil(floor), matchCeilingOrNil(ceiling));
  return matchCellingAndFloor(+value.toFixed());
};

export const matchFloatCriticalValue = (value) => {
  return matchIntegerCriticalValue(100, 0)(value * 100);
};
