const FileTableSkeleton = require('../../common/FileTableSkeleton');

module.exports = /* html */ `<div class="person-skeleton-body">
  <div class="person-skeleton-body-wrap">
    <div class="file-title-skeleton">
      <div class="title">
        <div class="title-inner">
          <span>全部文件</span></div>
      </div>
      <div class="action"><button type="button" class="ant-btn ant-btn-secondary"><i class="dk-iconfont dk-icon-shangchuan2"></i><span>上传</span></button><button type="button" class="ant-btn ant-btn-primary"><i class="dk-iconfont dk-icon-a-"></i><span>新建</span></button></div>
    </div>
    ${FileTableSkeleton}
  </div>
</div>`
