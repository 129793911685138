/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line max-classes-per-file
import { intl } from 'di18n-react';
import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { uploadStatus } from '@/components/Upload/UploadEngine';

export default class Operation extends Component {
  _renderStatus = () => {
    const uploadStatusComponents = {
      [uploadStatus.queueing.status]: <Queueing {...this.props} />,
      [uploadStatus.checking.status]: <Uploading {...this.props} />,
      [uploadStatus.uploading.status]: <Uploading {...this.props} />,
      [uploadStatus.paused.status]: <Paused {...this.props} />,
      [uploadStatus.cancelled.status]: <Cancelled {...this.props} />,
      [uploadStatus.failed.status]: <Failed {...this.props} />,
      [uploadStatus.success.status]: <Success {...this.props} />,
    };
    return uploadStatusComponents[this.props.fileItem.status.status] || null;
  };

  render() {
    return this._renderStatus();
  }
}

class Queueing extends Component {
  render() {
    return (
      <div className="operation">
        <div className="one" />
        <div className="hint two">{intl.t('排队中')}</div>
        <Tooltip
          placement="left"
          title={intl.t('取消上传')}>
          <i
            alt="OPERATION"
            onClick={() => {
              this.props.cancel(this.props.fileItem);
            }}
            className="three icon cancel"
          />
        </Tooltip>
      </div>
    );
  }
}

class Uploading extends Component {
  render() {
    return (
      <div className="operation">
        <div className="hint one">{this.props.fileItem.progress}%</div>
        <Tooltip
          placement="left"
          title={intl.t('暂停上传')}>
          <i
            alt="OPERATION"
            onClick={() => {
              window.__uploadCollect = window.__uploadCollect.filter(
                (f) => f.id !== this.props.fileItem.id,
              );
              this.props.pause(this.props.fileItem);
            }}
            className="pause two icon"
          />
        </Tooltip>
        <Tooltip
          placement="left"
          title={intl.t('取消上传')}>
          <i
            alt="OPERATION"
            onClick={() => {
              window.__uploadCollect = window.__uploadCollect.filter(
                (f) => f.id !== this.props.fileItem.id,
              );
              this.props.cancel(this.props.fileItem);
            }}
            className="three icon cancel"
          />
        </Tooltip>
      </div>
    );
  }
}

class Paused extends Component {
  render() {
    return (
      <div className="operation">
        <div className="hint one">{intl.t('已暂停')}</div>
        <Tooltip
          placement="left"
          title={intl.t('继续上传')}>
          <i
            alt="OPERATION"
            onClick={() => {
              this.props.goOn(this.props.fileItem);
            }}
            className="two icon continue"
          />
        </Tooltip>
        <Tooltip
          placement="left"
          title={intl.t('取消上传')}>
          <i
            alt="OPERATION"
            onClick={() => {
              this.props.cancel(this.props.fileItem);
            }}
            className="three icon cancel"
          />
        </Tooltip>
      </div>
    );
  }
}

class Cancelled extends Component {
  render() {
    return (
      <div className="operation">
        <div className="one" />
        <div className="hint two">{intl.t('已取消')}</div>
        <Tooltip
          placement="left"
          title={intl.t('重新上传')}>
          <i
            onClick={() => {
              this.props.retry(this.props.fileItem);
            }}
            alt="OPERATION"
            className="three icon retry"
          />
        </Tooltip>
      </div>
    );
  }
}

class Failed extends Component {
  componentDidMount() {
    window.__uploadCollect = window.__uploadCollect.filter(
      (f) => f.id !== this.props.fileItem.id,
    );
  }

  render() {
    return (
      <div className="operation">
        <div className="one" />
        <Tooltip
          placement="left"
          title={intl.t('重新上传')}>
          <i
            onClick={() => {
              this.props.retry(this.props.fileItem);
            }}
            alt="OPERATION"
            className="two icon failed"
          />
        </Tooltip>
        <Tooltip
          placement="left"
          title={intl.t('取消上传')}>
          <i
            alt="OPERATION"
            onClick={() => {
              this.props.cancel(this.props.fileItem);
            }}
            className="three icon cancel"
          />
        </Tooltip>
      </div>
    );
  }
}

class Success extends Component {
  componentDidMount() {
    if (window.__uploadCollect && window.__uploadCollect.length > 0) {
      const successFile = window.__uploadCollect.filter(
        (f) => f.id === this.props.fileItem.id,
      )[0];
      if (!successFile) return;
      const result = {
        // timingVar: `id:${successFile.id}-name:${successFile.name}-size:${successFile.size}-type:${successFile.type}`,
        timingCategory: intl.t('文件上传耗时-{slot0}', {
          slot0: successFile.id,
        }), // 指标字段
        timingName: `ftp-${successFile.id}`, // 指标名称(平台展示文案)
        timingValue: +new Date() - successFile.startTime,
      };
      window.__OmegaEvent(
        'tech_performance_custom',
        intl.t('性能自定义埋点'),
        result,
      );
    }
  }

  render() {
    return (
      <div className="operation">
        <div className="one" />
        <i
          alt="OPERATION"
          className="two icon success" />
        <Tooltip
          placement="left"
          title={intl.t('打开目录')}>
          <i
            alt="OPERATION"
            className="three icon jumpto"
            onClick={() => {
              this.props.jumpTo(this.props.fileItem.other);
            }}
          />
        </Tooltip>
      </div>
    );
  }
}
