/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-05 19:58:58
 * @LastEditTime: 2024-03-20 14:01:18
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/IconFamily/UserInfo/index.js
 *
 */
import { useSelector } from 'react-redux';
import classBind from 'classnames/bind';
import logout from '@/service/knowledge/login';
import { Popover } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { intl, getLocale, setLocale } from 'di18n-react';
import { isDC, openNewWindow } from '@/utils';
import './index.less';
import { useNavigate } from 'react-router-dom';
import {
  putTenantLoginInfo,
} from '@/pages/cooper/TenantLogin/service';
import { LITE_URL } from '@/constants';
import { apolloSwitch } from '@/utils/ab';
import styles from '@/components/serviceComponents/Header/style.module.less';

const cx = classBind.bind(styles);

const UserInfo = (props) => {
  const { isForbidTenantChang } = props;
  const { profile } = useSelector((state) => state.CooperIndex);
  const { tenantList, tenantId } = useSelector((state) => state.GlobalData);
  const [showLite, setShowLite] = useState(false);
  const includeDiDiTenant = tenantList.some(v => v.tenantId == 1)
  const navigate = useNavigate();

  useEffect(() => {
    initGrey();
  }, []);

  const initGrey = async () => {
    const data = await apolloSwitch('lite_switch');
    setShowLite(data);
  };

  const switchLocaleAction = () => {
    let curLocale = getLocale();

    if (curLocale === 'en-US') {
      curLocale = 'zh-CN';
    } else {
      curLocale = 'en-US';
    }

    window.g_di18n_hard_reload = true;
    setLocale(curLocale, { cookieLocaleKey: 'local_language' });
  };
  const goToLibray = () => {
    window.__OmegaEvent('ep_lab_ck', '', {
      platform: 'new',
    });
    navigate('/library');
  };
  // goToTenant
  const goToTenant = async (tenant) => {
    if (!tenant.selected) {
      const data = await putTenantLoginInfo(tenant?.tenantId)
      if (data) {
        window.localStorage.setItem('x-tenant-id', tenant?.tenantId);
        // 此时需要重新登录，更新cookie的值，进行刷新操作
        window.location.reload();
      }
    }
  };

  const _renderTenantChange = () => {
    return (
      <Fragment>
        {tenantList.map((v) => {
          return (
            <li
              key={v.tenantId}
              className={cx({
                'choose-li': v.selected,
              })}
              onClick={() => goToTenant(v)}>
              <span className={cx('tenantName')}>{v.tenantName}</span>
              {v.selected && <i
                className={
                  cx('dk-iconfont', 'dk-icon-gou', 'icon-selected')
                }
              />}
            </li>
          );
        })}
      </Fragment>
    );
  };
  const _renderContent = () => {
    const tenantCurrent = tenantList.filter((v) => v.selected)[0];
    return (
      <Fragment>
        <div className="userInfo">
          <div
            className="avatar"
            style={{ backgroundImage: `url(${profile.avatar})` }}
          />
          <div className="info">
            <span className="info-name">{profile.username_zh}</span>
            {tenantList.length > 1 && <span className="info-tenant">{tenantCurrent?.tenantName}</span>}
          </div>
        </div>
        <div className="line" />
        {
          !isForbidTenantChang && tenantList.length > 1 && (<Popover
            overlayClassName={`dk-ant-popover__reset ${cx(
              'loginOutChoose',
            )} knowLedge_loginOut changeTenantList`}
            content={_renderTenantChange()}
            placement={'right'}
          >
            <li
              key={'changeTenant'}
              className={cx('row flex-around')}>
              <span>{intl.t('切换组织')}</span>
              <span className={cx('arrow-tenant')} />
            </li>
          </Popover>)
        }
        <li
          key={'changeLanguage'}
          className={cx('row')}
          onClick={switchLocaleAction}>
          {getLocale() === 'zh-CN' ? intl.t('切换为英文') : intl.t('切换为中文')}
        </li>
        {/* 多租户逻辑处理，只要有滴滴租户，默认展示容灾版入口 */}
        {
          showLite && includeDiDiTenant && (
            <li
              key={'lite_cooper'}
              className={cx('row')}
              onClick={() => {
                window.__OmegaEvent('ep_disaster_tolerant_cooper_ck', '', {
                  platform: 'new',
                });
                openNewWindow(LITE_URL[process.env.APP_ENV]);
              }}
            >
              {intl.t('前往容灾版Cooper')}
            </li>
          )
        }
        {!isDC() && (
          <li
            key={'logout'}
            className={cx('row')}
            onClick={logout}>
            {intl.t('退出登录')}
          </li>
        )}
      </Fragment>
    );
  };
  return (
    <Popover
      overlayClassName={`dk-ant-popover__reset ${cx(
        'loginOutChoose',
      )} knowLedge_loginOut userInfoCard`}
      content={_renderContent(props.type)}
      placement={props.type === 'small' ? 'rightTop' : 'bottomLeft'}
    >
      <i
        className={`icon-user ${
          props.type === 'small' ? 'icon-user-small' : ''
        }`}
        style={{ backgroundImage: `url(${profile.avatar})` }}
      />
    </Popover>
  );
};

export default UserInfo;
