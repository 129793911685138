import replaceIntl from '../utils/wrapWithIntl.js';
import body from './components/body.js';
import header from './components/header.js';

const commonCss = require('../common/commonCss.js')

const headerWithIntl = replaceIntl(header)
const bodyWithIntl = replaceIntl(body)

const renderStr = /* html */`
<style>
  ${commonCss}
  /* right-person */
  #right-person p {
    margin-top   : 0;
    margin-bottom: 1em
  }

  #right-person .notify-list-small>span {
    left: 18px;
    top : 2px
  }

  #right-person :root {
    --swiper-theme-color: #007aff
  }

  #right-person :root {
    --swiper-navigation-size: 44px
  }

  #right-person .file-title-skeleton {
    padding        : 0 24px;
    line-height    : 22px;
    display        : flex;
    align-items    : center;
    justify-content: space-between
  }

  #right-person .file-title-skeleton .title {
    padding-right: 14px;
    display      : flex;
    align-items  : center
  }

  #right-person .file-title-skeleton .title .title-inner {
    display: flex;

    padding: 22px 0 12px
  }

  #right-person .file-title-skeleton .title .title-inner span {
    font-family: PingFangSC-Medium, PingFang SC;
    color      : #2f343c;
    font-weight: 500;
    line-height: 21px;
    font-size  : 16px
  }

  #right-person .file-title-skeleton .action {
    max-width  : 200px;
    display    : flex;
    align-items: center
  }

  #right-person .file-title-skeleton .action>button:last-child {
    margin-left: 12px
  }

  #right-person .global-search-wrap .search-tag {
    margin-left  : 8px;
    margin-bottom: 0 !important; /* NOTE 人工修订！ */
    padding      : 0 4px 0 8px;
    border-radius: 3px;
    background   : #ebeef1;
    display      : flex;
    align-items  : center;
    color        : rgba(0, 0, 0, .7)
  }

  #right-person .global-search-wrap .search-tag>span>i {
    color    : #333;
    font-size: 14px;
    cursor   : pointer
  }

  #right-person .space-skeleton-header {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding        : 20px 24px;
    overflow       : hidden
  }

  #right-person .space-skeleton-header .space-skeleton-header-title {
    height: 20px;
    width : 160px
  }

  #right-person .space-skeleton-header .space-skeleton-header-search {
    width: 633px;
    min-width: 308px;
    display: flex;
  }

  #right-person .space-skeleton-header .space-skeleton-button * {
    min-width: auto !important;
    width    : 100% !important;
    height   : 100% !important
  }

  #right-person .person-skeleton-box {
    height             : 100%;
    /* background-image   : url(/static/img/space-bg.png);*/ /* NOTE 资源太大了 */
    background-position: 0 0;
    background-size    : 100%;
    background-repeat  : no-repeat;
    display            : flex;
    flex-direction     : column
  }

  #right-person .person-skeleton-box .person-skeleton-body {
    height        : 100%;
    flex          : 1;
    overflow-y    : hidden;
    display       : flex;
    flex-direction: column;
    position      : relative
  }

  #right-person .person-skeleton-box .person-skeleton-body .person-skeleton-body-wrap {
    height       : 100%;
    background   : #fff;
    border-radius: 8px;
    margin       : 0 24px
  }

  #right-person .person-skeleton-box .person-skeleton-body .person-skeleton-body-wrap .file-skeleton-box {
    height  : calc(100% - 51px) !important;
    width   : 100% !important;
    overflow: hidden !important
  }

  #right-person .person-skeleton-box .person-skeleton-body .person-skeleton-body-wrap .file-header-skeleton-box {
    min-width: 100% !important;
    padding  : 0 24px !important
  }

  #right-person .person-skeleton-box .person-skeleton-body .person-skeleton-body-wrap .file-body-skeleton-box {
    height   : calc(100% - 40px) !important;
    min-width: 100% !important;
    width    : -webkit-max-content !important;
    width    : max-content !important;
    overflow : hidden !important;
    padding  : 8px 24px 0 24px !important
  }
  
  #right-person .dk-icon-rongqi:before {
    content: "\e74e"
  }
</style>
<div id="right-person" class="right">
  <div class="header-wrap"></div>
  <div class="body-wrap">
    <div class="person-skeleton-box">
      ${headerWithIntl}
      ${bodyWithIntl}
    </div>
  </div>
</div>
`
export default renderStr;


