import commonCss from '../common/commonCss';
import replaceIntl from '../utils/wrapWithIntl';
import body from './components/body';
import header from './components/header';

const headerWithIntl = replaceIntl(header)
const bodyWithIntl = replaceIntl(body)

const renderStr = /* html */ `
<style>
  ${commonCss}
  #right-team-detail html body .ant-btn-secondary  {
    background-color: #fff;
    border          : 1px solid #047ffe;
    color           : #047ffe
  }

  #right-team-detail .global-search-wrap .search-tag{
    /* NOTE 此样式为人工修订 */
    margin-left: 8px !important;
    padding: 0 4px 0 8px;
    border-radius: 3px;
    background: #EBEEF1;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.7);
        margin: 0;
        box-sizing: border-box;
        margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
  }

  #right-team-detail .file-title-skeleton  {
    padding        : 0 24px;
    line-height    : 22px;
    display        : flex;
    align-items    : center;
    justify-content: space-between
  }

  #right-team-detail .file-title-skeleton .title  {
    padding-right: 14px;
    display      : flex;
    align-items  : center
  }

  #right-team-detail .file-title-skeleton .title .title-inner  {
    display: flex;
    padding: 22px 0 12px
  }

  #right-team-detail .file-title-skeleton .title .title-inner span  {
    font-family: PingFangSC-Medium, PingFang SC;
    color      : #2f343c;
    font-weight: 500;
    line-height: 21px;
    font-size  : 16px
  }

  #right-team-detail .file-title-skeleton .action  {
    max-width  : 200px;
    display    : flex;
    align-items: center
  }

  #right-team-detail .file-title-skeleton .action>button:last-child  {
    margin-left: 12px
  }

  #right-team-detail .space-skeleton-header  {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding        : 20px 24px;
    overflow       : hidden
  }

  #right-team-detail .space-skeleton-header .space-skeleton-header-title  {
    height: 20px;
    width : 160px
  }

  #right-team-detail .space-skeleton-header .space-skeleton-header-search  {
    width: 633px;
    min-width: 308px;
    display: flex;
  }

  #right-team-detail .space-skeleton-header .space-skeleton-button *  {
    min-width: auto !important;
    width    : 100% !important;
    height   : 100% !important
  }

  #right-team-detail .td-skeleton-box  {
    height             : 100%;
    /* background-image   : url(/static/img/space-bg.png); */ /* NOTE 资源太大 */
    background-position: 0 0;
    background-size    : 100%;
    background-repeat  : no-repeat;
    display            : flex;
    flex-direction     : column
  }

  #right-team-detail .td-skeleton-box .td-skeleton-button *  {
    min-width: auto !important;
    width    : 100% !important;
    height   : 100% !important
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body  {
    flex          : 1;
    overflow-y    : hidden;
    display       : flex;
    flex-direction: column;
    position      : relative
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-bar  {
    margin         : 0 24px 16px;
    display        : flex;
    align-items    : center;
    height         : 32px;
    justify-content: normal
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-bar .td-skeleton-bar-tabs  {
    flex           : 1;
    display        : flex;
    justify-content: normal;
    align-items    : center;
    height         : 32px
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-bar .td-skeleton-bar-tabs .tab  {
    margin-right: 2px;
    position    : relative;
    width       : 114px;
    height      : 100%
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-bar .td-skeleton-bar-tabs .tab .icon  {
    position: absolute;
    left    : 10px;
    top     : 8px;
    width   : 16px;
    height  : 16px
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-bar .td-skeleton-bar-tabs .tab .title  {
    position: absolute;
    left    : 30px;
    top     : 9.5px;
    width   : 70px;
    height  : 13px
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-bar .td-skeleton-bar-icons  {
    display: flex
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-bar .td-skeleton-bar-icons-item  {
    color      : #6a707c;
    margin-left: 16px;
    cursor     : pointer;
    width      : 24px;
    height     : 24px;
    text-align : center;
    line-height: 24px
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-body-wrap  {
    height       : 100%;
    background   : #fff;
    border-radius: 8px;
    margin       : 0 24px
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-body-wrap .file-skeleton-box  {
    height  : calc(100% - 51px) !important;
    width   : 100% !important;
    overflow: hidden !important
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-body-wrap .file-header-skeleton-box  {
    min-width: 100% !important;
    padding  : 0 24px !important
  }

  #right-team-detail .td-skeleton-box .td-skeleton-body .td-skeleton-body-wrap .file-body-skeleton-box  {
    height   : calc(100% - 40px) !important;
    min-width: 100% !important;
    width    : -webkit-max-content !important;
    width    : max-content !important;
    overflow : hidden !important;
    padding  : 8px 24px 0 !important
  }
</style>
<div id="right-team-detail" class="right">
  <div class="header-wrap"></div>
  <div class="body-wrap">
    <div class="td-skeleton-box">
      ${headerWithIntl}
      ${bodyWithIntl}
    </div>
  </div>
</div>
`

export default renderStr;
