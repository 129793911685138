export default function navClick(item) {
  switch (item.path) {
    case '/': window.__OmegaEvent('ep_leftnavigation_home_ck', '', {
      platform: 'new',
    }); break;
    case '/disk': window.__OmegaEvent('ep_leftnavigation_personal_ck', '', {
      platform: 'new',
    }); break;
    case '/team-folder': window.__OmegaEvent('ep_leftba_teamspacehome_ck', '', {
      platform: 'new',
    }); break;
    case '/tome': window.__OmegaEvent('ep_leftbar_share_ck'); break;
    case '/favorite': window.__OmegaEvent('ep_leftbar_favorites_ck'); break;
    case '/trash': window.__OmegaEvent('ep_leftbar_trash_ck'); break;
    case '/dk': window.__OmegaEvent('ep_space_service_dk_ck', '', {
      platform: 'new',
    }); break;
    default: break;
  }
}
