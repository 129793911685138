export const abortPromise = (promise, timeout) => {
  let timeoutFn = null;
  let abort = null;
  let timeoutPromise = null;

  // 创建一个终止promise
  const abortPromise = new Promise((resolve, reject) => {
    abort = () => {
      reject(new Error('abort'));
    };
  });

  const racePromises = [promise, abortPromise];

  // 计时
  if (timeout) {
    // 创建一个超时promise
    timeoutPromise = new Promise((resolve, reject) => {
      timeoutFn = () => {
        reject(new Error('timeout'));
      };
    });
    racePromises.push(timeoutPromise);
    setTimeout(timeoutFn, timeout);
  }

  const racePromise = Promise.race(racePromises);
  racePromise.abort = abort;

  return racePromise;
};
