import { uploadError } from './constants';

export const checkFilesSize = (files) => (maxLength) => files.length > maxLength;

export const checkFilesAmount = (files) => {
  let allFileSize = 0;
  for (let file of files) {
    allFileSize += file.size;
  }
  return (maxSize) => allFileSize > maxSize;
};

export const checkFilesByType = (type) => type === uploadError.qualified.type;

export const getSizeOrAmountStatus = (files) => (config) => {
  if (checkFilesSize(files)(config.maxLength)) {
    return uploadError.excess;
  }
  // if (checkFilesAmount(files)(config.maxSize)) {
  //   return uploadError.oversize;
  // }
  return uploadError.qualified;
};
