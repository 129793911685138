import { fromJS } from 'immutable';
import { compose } from 'ramda';
import { uploadStatus } from '../util/constants';
import { acceptFileType } from '@/constants/uploadWiki';
import TaskPool from './tower';

const checkFileSecondsOrUpload = (hooks, config) => {
  TaskPool.getInstance().limitToUpload({ hooks, config });
};

// isNeedLimitType：外部空间中上传文件夹
const checkAnything = (hooks, config, isDir, isNeedLimitType) => (files) => {
  hooks.refreshResource(TaskPool.getInstance().initTaskPool().toJS());
  TaskPool.getInstance().registerPoolBack((pool) => {
    hooks.refreshResource(pool.toJS());
  });
  hooks.initIdsPromise(files, isDir, isNeedLimitType).then(({ taskId, token, strategyFiles, other }) => {
    const newPool = TaskPool.getInstance().getTaskPool().update('tasks', (tasks) => tasks.set(String(taskId), fromJS({
      taskId,
      token,
      size: strategyFiles.map((f) => f.file).reduce((a, b) => (a + b.size), 0),
      taskItems: strategyFiles.map((f) => ({
        id: f.fileId,
        file: f.file,
        isDir: (typeof f.isDir === 'boolean') ? f.isDir : config.isDir,
        status: uploadStatus.queueing,
        retry: 0,
        progress: 0,
        seqId: 0,
        other,
        token,
      })),
    })));
    hooks.refreshResource(TaskPool.getInstance().setTaskPool(newPool).toJS());
    checkFileSecondsOrUpload(hooks, config);
  }).catch((error) => {
  });
};

const checkFile = (hooks, config) => (files) => {
  checkAnything(hooks, config, false)(files);
};

const checkFolder = (hooks, config) => (files) => { // 目前没用的？
  checkAnything(hooks, config, true)(files);
};

const checkFileEvent = (hooks, config) => (e) => {
  const { files } = e.target;
  checkAnything(hooks, config, false)(files);
};

const checkFolderEvent = (hooks, config, isNeedLimitType) => (e) => {
  const { files } = e.target;
  checkAnything(hooks, config, true, isNeedLimitType)(files);
};

const createFileChooserDialogDom = (isDir, IsExternalTeam) => (hooks, config) => {
  const ele = document.createElement('input');
  ele.multiple = true;
  ele.type = 'file';
  ele.webkitdirectory = isDir;
  const issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  if (isDir && issafariBrowser) {
    // 黑魔法 safari 中只选择文件夹的属性失效了。用这个来模拟一下
    ele.accept = '...';
  }
  if (IsExternalTeam && !isDir) {
    ele.accept = acceptFileType;
  }

  ele.removeEventListener('change', checkFileEvent(hooks, config));
  ele.removeEventListener('change', checkFolderEvent(hooks, config));
  // 上传空文件夹不会触发change事件。所以目前不会有空文件夹提示
  ele.addEventListener('change', isDir ? checkFolderEvent(hooks, config, IsExternalTeam) : checkFileEvent(hooks, config), true);
  return ele;
};

const open = (ele) => {
  ele.value = '';
  ele.click();
};

export default {
  openFileChooserDialog:
    (isDir, IsExternalTeam) => compose(open, createFileChooserDialogDom(isDir, IsExternalTeam)),
  checkFiles:
    (files, { isDir, hooks, config }) => (isDir ? checkFolder(hooks, config)(files) : checkFile(hooks, config)(files)),
  uploadAnything: (files, { hooks, config, IsExternalTeam }) => checkAnything(
    hooks, config, undefined, IsExternalTeam,
  )(files), // 拖拽上传会用到
};
