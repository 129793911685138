import { intl } from 'di18n-react';
import React from 'react';
import { Progress } from 'antd';
import { uploadContainerStatus } from '@/components/Upload/UploadEngine';
import './index.less';

class Header extends React.Component {
  componentDidUpdate(prevProps) {
    const preCount = prevProps.data.count;
    const nowCount = this.props.data.count;

    if (nowCount) {
      if (
        preCount
        && preCount.success === nowCount.success
        && preCount.cancel === nowCount.cancel
        && preCount.amount === nowCount.amount
      ) {
        return;
      }
      this.checkOver(this.props.data);
    }
  }

  switchUploadList = () => {
    this.props.switchUploadList();
  };

  closeAll = (data) => {
    if (!this.props.showListSwitch) {
      this.props.closeAll(true, this.count);
      return;
    }

    const { success, cancel, amount } = data?.count ?? {};

    if (
      data.status
      && data.status.status !== uploadContainerStatus.checking.status
    ) {
      this.props.closeAll(
        success + cancel === amount && success + cancel > 0,
        this.count,
      );
    }
  };

  checkOver = (data) => {
    this.count = data.count;
    const { success, cancel, amount } = data.count;

    if (success + cancel === amount && success + cancel > 0) {
      // 因为header和body是异步执行的，为了保证每一个上传状态都展示出来，再提示全部成功，所以添加异步后置执行
      setTimeout(() => {
        this.props.success();
      }, 0);
    }
  };

  _renderDesc(data) {
    if (!this.props.showListSwitch) {
      return (
        <div className="desc">
          <i
            className="success"
            alt="ASYNC" />
          <div className="progress">{intl.t('全部上传成功')}</div>
        </div>
      );
    }

    if (!data || !data.status) {
      return (
        <div className="desc">
          <i
            className="async"
            alt="ASYNC" />
          <div className="progress">{intl.t('校验中...')}</div>
        </div>
      );
    }

    switch (data.status.status) {
      case uploadContainerStatus.checking.status:
        return (
          <div className="desc">
            <i
              className="async"
              alt="ASYNC" />
            <div className="progress">{intl.t('校验中...')}</div>
          </div>
        );

      case uploadContainerStatus.addTo.status:
        return (
          <div className="desc">
            <i
              className="async"
              alt="ASYNC" />
            <div className="progress">{intl.t('新增上传任务中...')}</div>
          </div>
        );

      case uploadContainerStatus.uploading.status:
        return (
          <div className="desc">
            <i
              className="async"
              alt="ASYNC" />
            <div className="progress">
              {intl.t('正在上传{slot0}/{slot1}', {
                slot0: data.count.success,
                slot1: data.count.amount,
              })}
            </div>
          </div>
        );

      case uploadContainerStatus.paused.status:
        return (
          <div className="desc">
            <i
              className="paused"
              alt="ASYNC" />
            <div className="progress">
              {intl.t('{slot0}个文件暂停', { slot0: data.count.paused })}
            </div>
          </div>
        );

      case uploadContainerStatus.success.status:
        return (
          <div className="desc">
            <i
              className="success"
              alt="ASYNC" />
            <div className="progress">
              {intl.t('{slot0}个文件上传成功', { slot0: data.count.success })}
            </div>
          </div>
        );

      case uploadContainerStatus.failed.status:
        return (
          <div className="desc">
            <i
              className="failed"
              alt="ASYNC" />
            <div className="progress">
              {intl.t('{slot0}个文件上传失败', { slot0: data.count.failed })}
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  render() {
    const { listSwitch, showListSwitch, data } = this.props;
    return (
      <div className="header">
        <div className="header-c">
          {this._renderDesc(data)}
          <div className="operation">
            <i
              alt="OPEN"
              className={listSwitch ? 'minimize' : 'expand'}
              onClick={this.switchUploadList}
            />

            <i
              alt="CLOSE"
              className={`close ${
                (data.status
                  && data.status.status
                    !== uploadContainerStatus.checking.status)
                || !showListSwitch
                  ? 'pointer'
                  : 'not-allowed'
              }`}
              onClick={() => this.closeAll(data)}
            />
          </div>
        </div>
        <div className="progress-line">
          <Progress
            strokeLinecap="square"
            status="active"
            size="small"
            className={'cooper-list-header-progress'}
            percent={data.status && data.status.status === uploadContainerStatus.success.status ? 100 : (data.progress || 0)}
            showInfo={false}
          />
        </div>
      </div>
    );
  }
}

export default Header;
