import commonCss from '../common/commonCss';
import replaceIntl from '../utils/wrapWithIntl';
import body from './components/body';

const bodyWithIntl = replaceIntl(body);

const renderStr = /* html */ `
<style>
  ${commonCss}
  #right-trash .trash-skeleton-box  {
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column
  }

  #right-trash .trash-skeleton-box .trash-skeleton-wrap  {
    flex: 1;
    overflow: hidden;
    padding : 0 32px
  }

  #right-trash .trash-skeleton-box .trash-skeleton-wrap .trash-skeleton-show-tip .ant-tabs-nav  {
    padding: 0 0 16px !important;
    margin : 0 !important
  }

  #right-trash .trash-skeleton-box .trash-skeleton-wrap .trash-skeleton-show-tip .file-skeleton-box  {
    min-height: 100% !important;
    margin-top: -12px !important
  }

  #right-trash .trash-skeleton-box .trash-skeleton-wrap .trash-skeleton-show-tip .file-body-skeleton-box  {
    height     : calc(100% - 68px) !important;
    min-width  : 100% !important;
    padding-top: 8px !important
  }

  #right-trash .trash-skeleton-box .trash-skeleton-wrap .trash-skeleton-unshow-tip .ant-tabs-nav  {
    padding: 0 !important;
    margin : 0 0 16px !important
  }

  #right-trash .trash-skeleton-box .trash-skeleton-wrap .trash-skeleton-unshow-tip .file-skeleton-box  {
    height    : 100% !important;
    margin-top: 40px !important
  }

  #right-trash .trash-skeleton-box .trash-skeleton-wrap .trash-skeleton-unshow-tip .file-body-skeleton-box  {
    height     : calc(100% - 68px) !important;
    min-width  : 100% !important;
    padding-top: 8px !important
  }
</style>
<div id="right-trash" class="right">
  ${bodyWithIntl}
</div>
`

export default renderStr;
