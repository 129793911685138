/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-24 17:35:07
 * @LastEditTime: 2023-07-28 16:50:44
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/Aside/MoreSystem/index.js
 *
 */
import { intl } from 'di18n-react';
import { openNewWindow } from '@/utils';
import { Popover } from 'antd';
import classBind from 'classnames/bind';
import { useMemo } from 'react';
import stylesCommon from '../style.module.less';
import styles from './style.module.less';

const cls = classBind.bind(styles);
const cx = classBind.bind(stylesCommon);
const DINOTE = 'diNote';

function MoreSystem({ children }) {
  const MoreSystemList = [
    {
      key: DINOTE,
      name: intl.t('氢笔记'),
      link: 'https://cooper.didichuxing.com/note',
    },
    {
      name: 'Wiki',
      link: 'http://wiki.intra.xiaojukeji.com/',
    },
    {
      name: intl.t('团队中心'),
      link: 'https://team.didichuxing.com/',
    },
    {
      name: 'Way社区',
      link: 'https://i.xiaojukeji.com/way/articles',
    },
    {
      name: '桔子学苑',
      link: 'https://didi.yunxuetang.cn/mix2/didi/#/mainIndex',
    },
    {
      name: intl.t('桔知'),
      link: 'https://zhishi.xiaojukeji.com/start/home',
    },
  ];

  const moreClick = (link) => {
    switch (link) {
      case 'http://wiki.intra.xiaojukeji.com/': window.__OmegaEvent('ep_leftbar_more_wiki_ck'); break;
      case 'https://didi.yunxuetang.cn/mix2/didi/#/mainIndex': window.__OmegaEvent('ep_leftbar_more_xueyuan_ck'); break;
      case 'https://zhishi.xiaojukeji.com/start/home': window.__OmegaEvent('ep_leftbar_more_zhishi_ck'); break;
      case 'https://team.didichuxing.com/': window.__OmegaEvent('ep_space_teamcenter_ck', '', { platform: 'new' }); break;
      default: break;
    }
  }

  const popContent = useMemo(() => {
    return (
      <ul
        className={cls('pop-content')}
        >
        {MoreSystemList.map((s, i) => {
          return (
            <React.Fragment key={i}>
              <li
                className={`${cx('item-name')} ${cls('item-name')}`}
                key={i}
                onClick={() => {
                  if (s.key === DINOTE) {
                    window.__OmegaEvent('ep_space_service_note_ck', '', { platform: 'new' })
                    openNewWindow(`${window.location.origin}/note`);
                    return;
                  }
                  moreClick(s.link);
                  openNewWindow(s.link);
                }}
            >
                <div className={`${cx('item-name-normal')}`}>
                  <div className={cx('item-container')}>
                    <div className={cx('item-text')}>{s.name}</div>
                  </div>
                </div>
              </li>
              {
                s.name === 'Wiki' && <>
                  <div className={cx('split-line')} />
                  <div className={cx('more-title')}>{intl.t('第三方')}</div>
                </>
              }
            </React.Fragment>
          );
        })}
      </ul>
    );
  }, []);

  return (
    <Popover
      overlayClassName={cls('more-system-popover')}
      content={popContent}
      trigger={['hover', 'click']}
      placement="rightTop"
      align={{
        offset: [0, 0],
      }}
    >
      {children}
    </Popover>
  );
}

export default MoreSystem;
