
export const uploadSize = {
  maxLength: 1000, // 最大上传数量
  maxSize: 1024 * 1024 * 1024 * 20, // 单次上传最大size
  sliceSize: 1024 * 1024 * 20, // 分片大小
  sliceBoundary: 1024 * 1024 * 20, // 低于此size不做切片处理
  taskPatchsSize: 100, // 创建上传任务接口单次创建最大数量
};

export const uploadError = {
  excess: {
    type: 'EXCESS',
    msg: ('数量超标'),
  },

  oversize: {
    type: 'OVERSIZE',
    msg: ('大小超标'),
  },

  qualified: {
    type: 'QUALIFED',
    msg: ('通过校验'),
  },
};
