export function checkDC() {
  return !!window.dcJSSDK;
}

// 兼容老版本dc
export function isSupportedSD() {
  if (!checkDC()) {
    return false;
  }

  const { version } = window.dcJSSDK;
  const [main, sub] = version && version.split('.');

  return main > 1 || (main >= 1 && sub >= 3);
}

export async function isShowSD() {
  if (!isSupportedSD()) {
    return false;
  }

  const isEnabled = await window.dcJSSDK.isFeatureEnabled({ key: 'use-cooper-sync-disk' });

  return isEnabled;
}

export function openSyncDisk() {
  window.dcJSSDK.shellService.openWindow({ key: 'sync-disk-window-application' });
}

// export function getDcVersion() {
//   let version = '';
//   const ua = window.navigator.userAgent;
//   let matchReg = /(D-Chat\/).*?(?=\s)/;
//   version = ua.match(matchReg)[0].split('/')[1];
//   return version;
// }

export function getDcVersion() {
  let version = '';
  const ua = window.navigator.userAgent;
  let matchReg = /(D-Chat\/).*?(?=\s)/;

  version = ua.match(matchReg)[0].split('/')[1];
  return version;
}

// // 判断用户当前版本是否小于指定版本
// export function isBeforeVersion(curr, origin) {
//   if (!curr) return true;
//   const currArray = curr.split('.');
//   const originArray = origin.split('.');
//   let isBefore = false;
//   let guard = 0;
//   while (guard <= origin.length) {
//     if (Number(currArray[guard]) < Number(originArray[guard])) {
//       isBefore = true;
//       break;
//     }
//     guard++;
//   }

//   return isBefore;
// }

// 判断用户当前版本是否小于指定版本
export function isBeforeVersion(curr, origin) {
  if (!curr) return true;
  const currArray = curr.split('.');
  const originArray = origin.split('.');
  let isBefore = false;

  for (let guard = 0; guard < Math.min(currArray.length, originArray.length); guard++) {
    const currNumber = Number(currArray[guard]);
    const originNumber = Number(originArray[guard]);

    if (currNumber < originNumber) {
      isBefore = true;
      break;
    } else if (currNumber > originNumber) {
      break;
    }
    // If the components are equal, continue to the next component
  }

  return isBefore;
}
