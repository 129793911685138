import React, { useEffect, useState } from 'react';
import closeIcon from './icon/close.png';
import warnIcon from './icon/warn.png';
import { getApolloConfig } from '@/utils/ab';
import styles from './index.module.less';
import classBind from 'classnames/bind';
import DOMPurify from 'dompurify';
import { getLocale } from 'di18n-react';

const cx = classBind.bind(styles);

const CooperAnnounce = () => {
  const [ announce, setAnnounce ] = useState('');

  useEffect(() => {
    getApolloConfig('cooper-announcement').then(res => {
      const en = getLocale() === 'en-US' ? '-en' : '';
      setAnnounce(res['announcement' + en] || res.announcement);
    });
  }, []);

  return (
    !!announce && (
      <div className={cx('cooper-announce', {
        'cooper-announce-dc': false
      })}>
        <img className={cx('warnIcon')} src={warnIcon} />
        <span>
          { DOMPurify.sanitize(announce) }
        </span>
        <button
          className={cx('btn-cc')}
          onClick={() => {
            setAnnounce('');
          }}
        >
          <img className={cx('closeIcon')} src={closeIcon} />
        </button>
      </div>
    )
  )
}

export default CooperAnnounce;

