import mountAnywhere from '@/utils/mountAnywhere';
import { intl } from 'di18n-react';
import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { sendTenantFeedback } from '@/service/cooper/index';
import { Form, Input, Modal, Radio, Checkbox, Row, Col, Button } from 'antd';
import style from './style.module.less';

const { TextArea } = Input;

const ContactMap = {
  dc: '请输入你的D-Chat用户名称或邮箱前缀',
  mail: '请输入你的邮箱地址',
  phone: '请输入你的电话',
  otherContact: '请说明你的联系方式',
}

const cx = classNames.bind(style);

function FeedbackModal({ onClose }) {
  const [form] = Form.useForm();
  const describeValue = Form.useWatch('describe', form);
  const contactTypeValue = Form.useWatch('contactType', form);

  const onFinish = async (e) => {
    await sendTenantFeedback({ e });
    onClose && onClose();
  }

  const oncancel = () => {
    onClose && onClose();
  }

  return <Modal
    width={564}
    visible={true}
    title={intl.t('反馈给Cooper')}
    destroyOnClose
    onCancel={onClose}
    footer={null}
    wrapClassName={cx('feedback-modal')}
  >
    <Form
      form={form}
      preserve={false}
      name='feedback'
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      footer={null}
      className={cx('feedback-form')}
    >
      <Form.Item
        name="type"
        label={intl.t('反馈类型')}
        required
      >
        <Radio.Group>
          <Radio value="question">{intl.t('使用咨询')} </Radio>
          <Radio value="problem">{intl.t('Bug缺陷')}</Radio>
          <Radio value="suggest">{intl.t('困惑与建议')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="client"
        label={intl.t('使用场景')}
        required
      >
        <Radio.Group>
          <Radio value="web">{intl.t('网页浏览器')} </Radio>
          <Radio value="mobile">{intl.t('手机移动端')}</Radio>
          <Radio value="pad">{intl.t('iPad端')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="module"
        label={intl.t('功能模块')}
        required
      >
        <Checkbox.Group>
          <Row gutter={[0, 6]}>
            <Col span={6}>
              <Checkbox value="upload">{intl.t('文件上传')}</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox value="shimo">{intl.t('协作文档/表格')}</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox value="preview">{intl.t('文件预览')}</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox value="share">{intl.t('文件分享')}</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox value="search">{intl.t('文件搜索')}</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox value="perm">{intl.t('权限使用')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value="other">{intl.t('其他（可在问题描述中阐述）')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="describe"
        label={intl.t('问题描述')}
      >
        <TextArea
          showCount
          maxLength={200}
          style={{
            height: 100,
            resize: 'none',
          }}
          className={describeValue?.length >= 200 ? 'red-num' : ''}
          placeholder={intl.t('请详细描述问题（选填，最多200个字）')}
        />
      </Form.Item>
      <Form.Item
        name="contactType"
        label={intl.t('希望通过什么方式联系你解决')}
        required
      >
        <Radio.Group>
          <Radio value="dc">{intl.t('D-Chat')} </Radio>
          <Radio value="mail">{intl.t('邮箱')}</Radio>
          <Radio value="phone">{intl.t('电话')}</Radio>
          <Radio value="otherContact">{intl.t('其他')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="contactMsg"
        label={intl.t('联系方式')}
        required
      >
        <Input placeholder={intl.t(ContactMap[contactTypeValue] ?? '请输入联系方式')} />
      </Form.Item>
      <div className={cx('btn-wrap')}>
        <Button
          htmlType="button"
          onClick={oncancel}
          className={cx('btn-cancel')} >{ intl.t('取消')}</Button>
        <Button
          type="primary"
          className={cx('btn-submit')}
          htmlType="submit">{ intl.t('提交')}</Button>

      </div>
    </Form>
  </Modal>
}


function helperFeedback() {
  let close = () => { };
  const Comp = (
    <FeedbackModal onClose={close} />
  );
  close = mountAnywhere(Comp);
}

export default helperFeedback;
