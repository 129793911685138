import GuideModal from '../GuideModal';
import { intl, getLocale } from 'di18n-react';
import step1 from './gif/1.gif';
import step1US from './gif/1-us.gif';


function NewPaintGuide() {
  const isUS = getLocale() === 'en-US';

  const data = [
    {
      title: '流程图、思维导图功能上新啦',
      img: isUS ? step1US : step1,
      summary: '快来体验Cooper全新功能吧！思维导图、流程图助你工作更上一层楼！',
    },
  ];
  return <GuideModal
    config={data}
    finishText={intl.t('我知道了')}
    userViewKey= {'GUIDE_DIALOG_PAINT'}
  />;
}

export default NewPaintGuide;
