/* eslint-disable max-len */
import { intl } from 'di18n-react';
import React from 'react';
import { getDocTypeName, handleUrl } from './utils';

export function NoticeDirectShare({ item, onRead }) {
  const { resourceName, isDir } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t(`向您分享了文件${isDir ? '夹' : ''}`)}
      &nbsp;
      <a
        className="notify-list-item-active"
        title={resourceName}
        href="/tome"
        target="_blank"
        onClick={onRead}
      >
        {resourceName}
      </a>
    </div>
  );
}

export function NoticeDocumentComment({ item, onRead }) {
  const { docName, docLink, content } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('在')}
      <a
        className="notify-list-item-active"
        title={docName}
        href={docLink}
        target="_blank"
        onClick={onRead}
      >
        {docName}
      </a>
      {intl.t('协作文档中发表了评论：')}
      {content.length <= 15 ? content : `${content.slice(0, 15)}...`}”
    </div>
  );
}

export function NoticeTeamInvitation({ item, onRead }) {
  const { teamName, teamUrl } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('邀请您加入团队')}
      <a
        className="notify-list-item-active"
        title={teamName}
        href={teamUrl}
        target="_blank"
        onClick={onRead}
      >
        {teamName}
      </a>
    </div>
  );
}

export function NoticeTeamDelivery({ item, onRead }) {
  const { teamName, teamUrl } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('将团队')}
      <a
        className="notify-list-item-active"
        title={teamName}
        href={teamUrl}
        target="_blank"
        onClick={onRead}
      >
        {teamName}
      </a>
      {intl.t('的所有权转交给了您')}
    </div>
  );
}

export function NoticeDocumentTransferred({ item, onRead }) {
  const { documentName, docLink, docType } = item.mergeFields;
  const type = getDocTypeName(docType);
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('将')}

      {type}
      <a
        className="notify-list-item-active"
        title={documentName}
        href={docLink}
        target="_blank"
        onClick={onRead}
      >
        {documentName}
      </a>
      {intl.t('的所有者转让给了您')}
    </div>
  );
}

export function NoticeDocumentTransferredOwnerNotice({ item, onRead }) {
  const { spaceName, documentName, docLink, transferTo, docType } = item.mergeFields;
  const team = spaceName;
  const type = getDocTypeName(docType);
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('已将')}
      {team}
      {intl.t('空间的')}
      {type}
      <a
        className="notify-list-item-active"
        title={documentName}
        href={docLink}
        target="_blank"
        onClick={onRead}
      >
        {documentName}
      </a>
      {intl.t('的所有者转让给了')}
      {transferTo}
      {/* 已将{team}空间的{type}{name()}的所有者转让给了{transferTo} */}
    </div>
  );
}

export function NoticeTeamDeletion({ item }) {
  const { spaceName, spaceOwnerName } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      <a
        className="notify-list-item-active"
        title={spaceName}
        target="_blank">
        {spaceName}
      </a>
      {intl.t('团队已被团队所有者')}

      <a
        className="notify-list-item-active"
        title={spaceOwnerName}
        target="_blank"
      >
        {spaceOwnerName}
      </a>
      {intl.t('删除,您在该团队里的文件已被放入')}
      {spaceOwnerName}
      {intl.t('回收站将会保留30天。如需使用请联系')}
      <a
        className="notify-list-item-active"
        title={spaceOwnerName}
        target="_blank"
      >
        {spaceOwnerName}
      </a>
      {/* {team()}{spaceOwnerNameContent()}删除,您在该团队里的文件已被放入{spaceOwnerName}回收站将会保留10天。如需使用请联系{spaceOwnerNameContent()} */}
    </div>
  );
}

export function NoticeDocumentThumbsup({ item, onRead }) {
  const { docName, docLink } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('点赞了协作文档')}

      <a
        className="notify-list-item-active"
        title={docName}
        href={docLink}
        target="_blank"
        onClick={onRead}
      >
        {docName}
      </a>
    </div>
  );
}

export function NoticeDocRefer({ item, onRead }) {
  const { docName, docUrl, docType, docTypeName } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {docType === 'shimoWord'
      || docType === 'SHIMO2_WORD'
      || docType === 'SHIMO2_EXCEL'
        ? docType === 'SHIMO2_EXCEL'
          ? intl.t('在协作表格')
          : intl.t('在协作文档')
        : intl.t('在氢文档')}

      <a
        className="notify-list-item-active"
        title={docName}
        href={docUrl}
        target="_blank"
        onClick={onRead}
      >
        {docName}
      </a>
      {intl.t('中@了你')}
    </div>
  );
}

export function NoticeCommentRefer({ item, onRead }) {
  const { docName, url, docType } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('在协作')}

      {docType === 'SHIMO2_EXCEL' ? intl.t('表格') : intl.t('文档')}

      <a
        className="notify-list-item-active"
        title={docName}
        href={url}
        target="_blank"
        onClick={onRead}
      >
        {docName}
      </a>
      {intl.t('中@了你')}
    </div>
  );
}

export function PageAtNotice({ item, onRead }) {
  const { docName, docUrl } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('在知识库页面')}
      <a
        className="notify-list-item-active"
        title={docName}
        href={docUrl}
        target="_blank"
        onClick={onRead}
      >
        {docName}
      </a>
      {intl.t('中@了你')}
    </div>
  );
}

export function ApplyOutSpaceAgree({ item, onRead }) {
  const { teamName, bpmUrl, pcContentSourceUrl } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('您提交的“开通外部空间申请”')}
      <a
        className="notify-list-item-active"
        title={teamName}
        href={bpmUrl}
        target="_blank"
        onClick={onRead}
      >
        {intl.t('已审批通过，')}
      </a>
      <a
        className="notify-list-item-active"
        title={teamName}
        href={pcContentSourceUrl}
        target="_blank"
        onClick={onRead}
        >
        {teamName}
      </a>
      {intl.t('创建成功，请开启内外协作吧！')}
    </div>
  );
}
export function ApplyOutSpaceReject({ item, onRead }) {
  const { teamName, bpmUrl, approvalMessage } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('您提交的“开通外部空间申请”')}
      <a
        className="notify-list-item-active"
        title={teamName}
        href={bpmUrl}
        onClick={onRead}
        target="_blank"
      >
        {intl.t('已被驳回，')}
      </a>
      {
        approvalMessage && `${intl.t('驳回原因：')} ${approvalMessage}。`
      }
      <span className='notify-list-item-bold-name'>
        {teamName}
      </span>
      {intl.t('创建失败，如有需求请在Cooper重新创建或在BPM申请页面修改后重新提交。')}
    </div>
  );
}

export function SpaceOwnerApplyCompleteMessage({ item, onRead }) {
  const { spaceName, approvalMessage, status } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {
        status === 'Agree' ? intl.t('您已成为') : intl.t('您对')
      }
      <a
        className="notify-list-item-active"
        title={spaceName}
        href={handleUrl(item)}
        onClick={onRead}
        target="_blank"
      >
        {spaceName}
      </a>
      {/* {
        (item?.mergeFields.relationTypeTags || []).indexOf('IN_OUT') > -1 && (
          <span>（{intl.t('外部')}）</span>
        )
      } */}
      {
        status === 'Agree' ? intl.t('的所有者。') : status === 'Reject' ? intl.t('所有权的申请已被拒绝。') : intl.t('所有权的申请已失效，空间已被删除。')
      }
      {
       (status === 'Agree' || status === 'Reject') && approvalMessage && `${intl.t('审批理由：')} ${approvalMessage}。`
      }
    </div>
  );
}
export function DocOwnerApplyCompleteMessage({ item, onRead }) {
  const { resourceName, status, approvalMessage } = item.mergeFields;
  return (
    <div className="notify-list-item-detail-desc">
      {
        status === 'Agree' ? intl.t('您已成为') : intl.t('您对')
      }
      <a
        className="notify-list-item-active"
        title={resourceName}
        href={handleUrl(item)}
        onClick={onRead}
        target="_blank"
      >
        {resourceName}
      </a>
      {/* {
        (item?.mergeFields.relationTypeTags || []).indexOf('IN_OUT') > -1 && (
          <span>（{intl.t('外部')}）</span>
        )
      } */}
      {
        status === 'Agree' ? intl.t('的所有者。') : status === 'Reject' ? intl.t('所有权的申请已被拒绝。') : intl.t('所有权的申请已失效，文档已被删除。')
      }
      {
       (status === 'Agree' || status === 'Reject') && approvalMessage && ` ${intl.t('审批理由：')} ${approvalMessage}。`
      }
    </div>
  );
}
